import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import "intl-tel-input/build/css/intlTelInput.css";
import "react-phone-number-input/style.css";
import "react-dropzone-uploader/dist/styles.css";
import axios from "axios";
import moment from "moment";
import dateLeft from "../../assets/dateLeft.svg";
import dateLeftBlack from "../../assets/dateLeftBlack.svg";
import dateRight from "../../assets/dateRight.svg";
import sodelovanje from "../../assets/sodelovanje.svg";
import { useEffect } from "react";
import doctorIntro from "../../assets/NYD/imagesquestionary/doctorIntroNYD.png";
import { Grid } from "@material-ui/core";

//Dinamic Images
const registration_success =
  require(`../../assets/${process.env.REACT_APP_PROJECT}/registration_success.svg`).default;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
    padding: "1em 5em",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  padded: {
    padding: "3em 15em 2em 15em",
  },
}));

const queryParams = new URLSearchParams(window.location.search);
// ova trazi po url parametre

let patientId = queryParams.get("pacientId");
// selektuje koji parametar da trazi
let ackitId = queryParams.get("ackitId");

export default function HorizontalLinearStepper() {
  import("./App.css");

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const [state, setState] = React.useState({
    dateCounter: 0,
    availableSlots: [],
    filteredCurrentDay: [],
    filteredSecondDay: [],
    filteredThirdDay: [],
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "070",
    birthday: "",
    firstQuestion: "",
    secondQuestion: "",
    thirdQuestion: "",
    fourthQuestion: "",
    fifthQuestion: "",
    painLevel: undefined,
    moski: false,
    selectedTimeSlot: "",
    policyNumber: "",
    file: [],
    uniqueCodeInput: "",
    agreeCheck: false,
    unqieCodeValid: false,
  });

  useEffect(() => {
    getAvailableSlotsForThreeDays();
  }, []);

  function getSteps() {
    return ["Izbira termina", "Zaključek"];
  }

  const agreeCheckboxHandler = () => {
    setState({ ...state, agreeCheck: !agreeCheck });
  };

  const uniqueCodeValidator = (uniqueCodeInput) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}policies/${uniqueCodeInput}`)
      .then((res) => {
        console.log("TAX NUMBER", res.data.taxNumber);
        console.log("RES DATA", res);
        if (res.data.taxNumber === uniqueCodeInput) {
          setState({ ...state, uniqueCodeValid: true });
        } else {
          setState({ ...state, uniqueCodeValid: false });
        }
      });

    console.log(uniqueCodeValid, "uniqueCodeValid");
  };

  const stepValidator = (step) => {
    switch (step) {
      case 0:
        return (
          <div className="firstStepButton">
            <Button
              disabled={uniqueCodeValid ? (agreeCheck ? false : true) : true}
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}
              id="redButton"
            >
              {activeStep === steps.length - 1 ? "Potrdi" : "Naprej"}
            </Button>
          </div>
        );
      case 1:
        return (
          <Button
            disabled={selectedTimeSlot.length < 1 ? true : false}
            variant="contained"
            color="primary"
            onClick={handleNext}
            className={classes.button}
            id="redButton"
          >
            {activeStep === steps.length - 1 ? "Potrdi" : "Naprej"}
          </Button>
        );
      case 2:
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              createSession(state);
              handleNext();
            }}
            className={classes.button}
            id="redButton"
          >
            Potrdi
          </Button>
        );
    }
  };

  const createSession = async (state) => {
    await createAppointment();
    await deleteSelectedTimeSlot(selectedTimeSlot.id);
  };

  const createAppointment = async () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}sessions/`, {
        pacientId: patientId,
        birthday: ackitId,
        doctorId: selectedTimeSlot.doctor,
        sessionDate: selectedTimeSlot.startTime,
        sessionStartTime: selectedTimeSlot.startTime,
        firstName: "Luka",
        lastName: "Dudic",
        email: "dukaludic@gmail.com",
        options: {
          policyIssuer: "NYD",
          callType: "video",
        },
        phone: "+386" + phoneNumber,
      })
      .then((result) => {
        console.log("OVO JE REQUEST", result);
        var formData = new FormData();
        formData.append("id", result.data.id);
        file.map((item) => {
          formData.append("file", item);
        });
        updateSessionWithFiles(result, formData);
      })
      .catch((reason) => {
        console.log("request nije yupsei", reason);
      });
  };

  const updateSessionWithFiles = async (result, formData) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}sessions/${result.data.id}`,
        formData,
        {
          headers: { "Content-type": "multipart/form-data" },
        }
      )
      .then((response) => console.log("THIS IS THE UPDATE REQUEST", response));
  };

  const deleteSelectedTimeSlot = async (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}availabilitySlots/${id}`)
      .then((response) => console.log(response));
  };

  const {
    dateCounter,
    selectedTimeSlot,
    phoneNumber,
    file,
    uniqueCodeInput,
    agreeCheck,
    uniqueCodeValid,
  } = state;

  const removeDuplicateObjectFromArray = (array, key) => {
    var check = new Set();
    return array.filter((obj) => !check.has(obj[key]) && check.add(obj[key]));
  };

  const getAvailableSlotsForThreeDays = async () => {
    axios(`${process.env.REACT_APP_API_URL}availabilitySlots/slots`).then(
      (response) => {
        var datePlusOne = new Date().setDate(new Date().getDate() + 1);
        var datePlusTwo = new Date().setDate(new Date().getDate() + 2);

        var firstDay = response.data.filter(
          (item) => new Date(item.startTime).getDate() === new Date().getDate()
        );
        var secondDay = response.data.filter(
          (item) =>
            new Date(item.startTime).getDate() ===
            new Date(datePlusOne).getDate()
        );
        var thirdDay = response.data.filter(
          (item) =>
            new Date(item.startTime).getDate() ===
            new Date(datePlusTwo).getDate()
        );

        var firstDayFiltered = removeDuplicateObjectFromArray(
          firstDay,
          "startTime"
        );
        var secondDayFiltered = removeDuplicateObjectFromArray(
          secondDay,
          "startTime"
        );
        var thirdDayFiltered = removeDuplicateObjectFromArray(
          thirdDay,
          "startTime"
        );

        firstDayFiltered.sort(
          (a, b) =>
            new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
        );
        secondDayFiltered.sort(
          (a, b) =>
            new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
        );
        thirdDayFiltered.sort(
          (a, b) =>
            new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
        );

        setState({
          ...state,
          availableSlots: response.data,
          filteredCurrentDay: firstDayFiltered,
          filteredSecondDay: secondDayFiltered,
          filteredThirdDay: thirdDayFiltered,
        });
      }
    );
  };

  const reserveSelectedTimeSlot = (id) => {
    console.log(
      "WE NEED THIS TO UPDATE: OLD",
      selectedTimeSlot.id,
      "New State",
      id
    );
    axios.patch(
      `${process.env.REACT_APP_API_URL}availabilitySlots/${selectedTimeSlot.id}`,
      {
        pending: "false",
      }
    );
    axios.patch(`${process.env.REACT_APP_API_URL}availabilitySlots/${id}`, {
      pending: "true",
    });
  };

  const renderDateTimeSlots = (dateCounter) => {
    switch (dateCounter) {
      case 0:
        return (
          <>
            {state.filteredCurrentDay.map((slot) => (
              <>
                {slot.pending == true ? (
                  <></>
                ) : (
                  <div
                    key={slot.id}
                    data-id={slot.doctor}
                    data-slot={slot.id}
                    data-pending={slot.pending}
                    className={`item ${
                      selectedTimeSlot.startTime === slot.startTime
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => {
                      reserveSelectedTimeSlot(slot.id);
                      setState({ ...state, selectedDoctor: slot.doctor });
                      setState({ ...state, selectedTimeSlot: slot });
                    }}
                  >
                    {console.log(
                      new Date(slot.startTime),
                      new Date(slot.startTime).getHours()
                    )}
                    {new Date(slot.startTime).getHours().toString().length <= 1
                      ? "0" + new Date(slot.startTime).getHours().toString()
                      : new Date(slot.startTime).getHours().toString()}
                    :
                    {new Date(slot.startTime).getMinutes().toString().length <=
                    1
                      ? new Date(slot.startTime).getMinutes().toString() + "0"
                      : new Date(slot.startTime).getMinutes().toString()}
                  </div>
                )}
              </>
            ))}
          </>
        );
      case 1:
        return (
          <>
            {state.filteredSecondDay.map((slot) => (
              <>
                {slot.pending == true ? (
                  <></>
                ) : (
                  <div
                    key={slot.id}
                    data-id={slot.doctor}
                    data-slot={slot.id}
                    className={`item ${
                      selectedTimeSlot.startTime === slot.startTime
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => {
                      reserveSelectedTimeSlot(slot.id);
                      setState({ ...state, selectedDoctor: slot.doctor });
                      setState({ ...state, selectedTimeSlot: slot });
                    }}
                  >
                    {console.log(
                      new Date(slot.startTime),
                      new Date(slot.startTime).getHours()
                    )}
                    {new Date(slot.startTime).getHours().toString().length <= 1
                      ? "0" + new Date(slot.startTime).getHours().toString()
                      : new Date(slot.startTime).getHours().toString()}
                    :
                    {new Date(slot.startTime).getMinutes().toString().length <=
                    1
                      ? new Date(slot.startTime).getMinutes().toString() + "0"
                      : new Date(slot.startTime).getMinutes().toString()}
                  </div>
                )}
              </>
            ))}
          </>
        );
      case 2:
        return (
          <>
            {state.filteredThirdDay.map((slot) => (
              <>
                {slot.pending == true ? (
                  <></>
                ) : (
                  <div
                    key={slot.id}
                    data-id={slot.doctor}
                    data-slot={slot.id}
                    className={`item ${
                      selectedTimeSlot.startTime === slot.startTime
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => {
                      reserveSelectedTimeSlot(slot.id);
                      setState({ ...state, selectedDoctor: slot.doctor });
                      setState({ ...state, selectedTimeSlot: slot });
                    }}
                  >
                    {console.log(new Date(slot.startTime))}
                    {new Date(slot.startTime).getHours().toString().length <= 1
                      ? "0" + new Date(slot.startTime).getHours().toString()
                      : new Date(slot.startTime).getHours().toString()}
                    :
                    {new Date(slot.startTime).getMinutes().toString().length <=
                    1
                      ? new Date(slot.startTime).getMinutes().toString() + "0"
                      : new Date(slot.startTime).getMinutes().toString()}
                  </div>
                )}
              </>
            ))}
          </>
        );
      default:
        return (
          <>
            {state.filteredCurrentDay.map((slot) => (
              <div
                key={slot.id}
                data-id={slot.doctor}
                data-slot={slot.id}
                className={`item ${
                  selectedTimeSlot === slot.startTime ? "selected" : ""
                }`}
                onClick={() => {
                  reserveSelectedTimeSlot(slot.id);
                  setState({ ...state, selectedTimeSlot: slot });
                }}
              >
                {new Date(slot.startTime).getHours().toString().length <= 1
                  ? "0" + new Date(slot.startTime).getHours().toString()
                  : new Date(slot.startTime).getHours().toString()}
                :
                {new Date(slot.startTime).getMinutes().toString().length <= 1
                  ? new Date(slot.startTime).getMinutes().toString() + "0"
                  : new Date(slot.startTime).getMinutes().toString()}
              </div>
            ))}
          </>
        );
    }
  };

  function getStepContent(step, state) {
    require("moment/locale/sl.js");

    switch (step) {
      case 0:
        return (
          <>
            <Grid alignItems="center" container noWrap spacing={3}>
              <Grid item xs={12} xm={6} lg={6}>
                <div className="videoPosvet">
                  <h1>Video Posvet</h1>
                  <Typography>
                    Za prijavo na videoposvet z zdravnikom vas prosimo, da
                    vnesete vašo unikatno kodo katero ste dobili na izvidu ali
                    e-pošte.
                  </Typography>
                  <form className="videoPosvetForm">
                    <label>Unikatna koda</label>
                    <input
                      onChange={(e) => {
                        setState({
                          ...state,
                          uniqueCodeInput: e.target.value,
                        });
                        uniqueCodeValidator(e.target.value);
                      }}
                      type="text"
                      placeholder="Unikatna koda"
                    ></input>
                  </form>
                  <div className="videoPosvetCheckbox">
                    <input
                      onClick={agreeCheckboxHandler}
                      style={{ marginRight: "1em" }}
                      type="checkbox"
                      checked={agreeCheck}
                    />
                    <p
                      style={{
                        fontSize: "11px",
                        textAlign: "justify",
                        font: "normal normal normal 16px/25px Proxima Nova",
                        color: "#19191A",
                      }}
                    >
                      Sprejmi{" "}
                      <a
                        href="https://merkur.nyd.care/politika-zasebnosti"
                        target="_blank"
                      >
                        Politiko zasebnosti
                      </a>{" "}
                      in{" "}
                      <a
                        href="https://merkur.nyd.care/splosni-pogoji-uporabe"
                        target="_blank"
                      >
                        Varstvo podatkov
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} xm={6} lg={6}>
                <img
                  className="videoPosvetImg"
                  style={{
                    maxWidth: "300",
                    maxHeight: "auto",
                  }}
                  src={doctorIntro}
                ></img>
              </Grid>
            </Grid>
          </>
        );

      case 1:
        return (
          <div>
            <Grid container spacing={3}>
              <Grid item xm={6} lg={6}>
                <div
                  // style={{ maxWidth: "400px", padding: "20px" }}
                  className="davcnaFormText"
                >
                  <h1>Izbira termina</h1>
                  <p>
                    Prosimo vas, da izberete termin, ki vam ustreza, za
                    videoposvet z zdravnikom.
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                        alignContent: "flex-end",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        style={{ width: "40px", height: "40px" }}
                        src={dateCounter != 0 ? dateLeftBlack : dateLeft}
                        onClick={() =>
                          setState({
                            ...state,
                            dateCounter: dateCounter == 0 ? 0 : dateCounter - 1,
                          })
                        }
                      ></img>
                    </div>
                    <div
                      style={{
                        flex: 4,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#575757",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "ProximaNova !important",
                          }}
                        >
                          Danes:
                        </div>
                        <div
                          style={{
                            fontFamily: "ProximaNova !important",
                            fontWeight: "700",
                            textTransform: "capitalize",
                          }}
                        >
                          {moment(
                            new Date(
                              new Date().setDate(
                                new Date().getDate() + dateCounter
                              )
                            ).toDateString()
                          )
                            .locale("sl")
                            .format("dddd LL")}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        alignContent: "flex-end",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        style={{ width: "40px", height: "40px" }}
                        src={dateRight}
                        onClick={() =>
                          setState({
                            ...state,
                            dateCounter: dateCounter == 2 ? 2 : dateCounter + 1,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="timeSlots">
                    {renderDateTimeSlots(dateCounter)}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} xm={6} lg={6}>
                <div className="davcnaFormImg">
                  <img className="doctorIntro" src={doctorIntro}></img>
                </div>
              </Grid>
            </Grid>
          </div>
        );
      default:
        return "Unknown step";
    }
  }

  const isStepOptional = (step) => {};

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  return (
    <div className={classes.root} id="questionaryRoot">
      <div className="allFontsToBeBoston">
        <div className="questionaryView">
          {activeStep === steps.length ? (
            <div className="stepBoxFinal">
              <div className="davcnaFormCenter davcnaForm">
                <div className="paddedBox">
                  <h1>Uspešno ste rezervirali posvet</h1>
                  <p>
                    Potrdilo o uspešni rezervaciji termina in vabilo na posvet
                    boste prejeli na elektronski naslov in preko SMS sporočila.
                  </p>
                </div>
                <img src={registration_success}></img>
              </div>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep, state)}
              </Typography>
              <Grid container>
                <Grid item xs={12} xm={6} lg={6}>
                  <div className="stepBoxControllNew">
                    {activeStep === 0 ? (
                      <></>
                    ) : (
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                        style={{ background: "#E0E0E0" }}
                      >
                        Nazaj
                      </Button>
                    )}
                    {isStepOptional(activeStep) && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSkip}
                        className={classes.button}
                      >
                        Skip
                      </Button>
                    )}

                    {stepValidator(activeStep)}
                  </div>
                </Grid>
              </Grid>
            </div>
          )}

          {/* <img style={{ marginBottom: "25px" }} src={sodelovanje}></img> */}
        </div>
      </div>
    </div>
  );
}
