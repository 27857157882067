import React from 'react';

export default function PolitikaZasebnosti() {
  import('../App.css');

  return (
    <div style={{ margin: "0 auto" }}>
      <div className="containrforPrivacyPolicy">
        <body class="c25">
          <div>
            <p class="c15 c14 c28">
              <span class="c21"></span>
            </p>
          </div>
          <p class="c28 c32">
            <span class="c5">POLITIKA ZASEBNOSTI</span>
          </p>
          <p class="c12">
            <span class="c5"></span>
          </p>
          <p class="c4">
            <span class="c11">
              Ta politika zasebnosti, skupaj s Splo&scaron;nimi pogoji uporabe
              spletne strani in Politiko uporabe pi&scaron;kotkov,
              &nbsp;dolo&#269;a na&#269;ine uporabe osebnih podatkov, ki jih od
              vas, z va&scaron;o uporabo v zvezi z uporabo spletne strani
            </span>
            <span>&nbsp;</span>
            <span>https://izkasljaj.nyd.care/ </span>
            <sup>
              <a href="#cmnt1" id="cmnt_ref1">
                [a]
              </a>
            </sup>
            <span class="c0">
              in vseh morebitnih podstrani te spletne strani (&raquo;spletna
              stran&laquo;), pridobi upravljavec Dru&scaron;tvo plju&#269;nih in
              alergijskih bolnikov Slovenije &ndash; DPABS ter obdelovalec NYD
              d.o.o., z registriranim naslovom in poslovnim naslovom na
              Pre&scaron;ernova cesta 19, 1000 Ljubljana (v nadaljevanju
              &quot;NYD&quot;, &quot;mi&quot;, &quot;nas&quot;).
            </span>
          </p>
          <p class="c4">
            <span class="c0">
              Z uporabo storitev Posvet z zdravnkm dovoljujete, da NYD d.o.o.
              posreduje podatke o uporabi storitve Dru&scaron;tvo plju&#269;nih
              in alergijskih bolnikov Slovenije &ndash; DPABS (t.j. datum
              registracije, datumi posameznih prijav v sistem, tip storitve,
              takoj&scaron;nja obravnava ali rezervacija termina) Dru&scaron;tvo
              plju&#269;nih in alergijskih bolnikov Slovenije &ndash; DPABS, ki
              jih lahko do va&scaron;ega preklica uporablja za namen analize
              uporabe storitve, skladno s to politiko zasebnosti. Preklic lahko
              podate kadarkoli in brez kakr&scaron;nihkoli stro&scaron;kov.
            </span>
          </p>
          <p class="c4">
            <span class="c0">
              Za namene uporabe storitve Dru&scaron;tvo plju&#269;nih in
              alergijskih bolnikov Slovenije &ndash; DPABS, ki omogo&#269;a
              uporabnikom (&raquo;vas&laquo;, &raquo;vam&laquo;) posvet in
              mo&#382;nost oddaje neposrednega povpra&scaron;evanja na
              zdravstvene ali z zdravjem povezane storitve in oddajo ocene o
              zadovoljstvu z opravljeno zdravstveno ali z zdravjem povezano
              &nbsp;storitvijo na spletni strani, v tej politiki zasebnosti
              nastopa:
            </span>
          </p>
          <p class="c4">
            <span class="c11">
              Dru&scaron;tvo plju&#269;nih in alergijskih bolnikov Slovenije
              &ndash; DPABS, Koprska ulica 94, 1000 Ljubljana, Slovenija,{" "}
            </span>
            <span>dpbs@siol.net</span>
            <span class="c11">&nbsp;+386 (0)1 427 44 44 kot upravljalec, </span>
          </p>
          <p class="c4">
            <span class="c11">
              NYD d.o.o, Pre&scaron;ernova cesta 19, 1000 Ljubljana,{" "}
            </span>
            <span class="c11 c30">
              <a class="c29" href="mailto:info@nyd.care">
                info@nyd.care
              </a>
            </span>
            <span class="c0">, 051 426 911, kot obdelovalec,</span>
          </p>
          <p class="c4">
            <span class="c0">
              Zdravniki in zdravstveni sodelavci ter druge osebe, v skladu s to
              politiko zasebnosti kot pod-obdelovalci.
            </span>
          </p>
          <p class="c4">
            <span class="c11">
              Za uporabo Posvet z zdravnikom ima Dru&scaron;tvo plju&#269;nih in
              alergijskih bolnikov Slovenije &ndash; DPABS sklenjeno posebno
              pogodbo o obdelavi osebnih podatkov, ki natan&#269;no ureja kako,
              in pod kak&scaron;nimi pogoji smejo NYD d.o.o. &nbsp;ter njegovi
              podobdelovalci obdelovati osebne podatke,glede katerih
              Dru&scaron;tvo plju&#269;nih in alergijskih bolnikov Slovenije
              &ndash; DPABS nastopa kot upravljalec{" "}
            </span>
            <span class="c11 c13">
              (npr. osebna imena, naslovi, elektronski naslovi, IP naslovi, in
              drugi podatki, ki se nana&scaron;ajo na uporabnike).
            </span>
          </p>
          <p class="c10 c14">
            <span class="c0"></span>
          </p>
          <ol class="c1 lst-kix_list_11-0 start" start="1">
            <li class="c6 li-bullet-0">
              <span class="c5">
                Osebni podatki, zbrani z uporabo kontaktnega obrazca na Spletni
                strani
              </span>
            </li>
          </ol>
          <ol class="c1 lst-kix_list_6-0 start" start="1">
            <li class="c7 li-bullet-0">
              <span class="c5">
                Zbrani osebni podatki in podlaga za zbiranje
              </span>
            </li>
          </ol>
          <p class="c10 c17">
            <span class="c0">
              NYD zbira podatke o va&scaron;em imenu, priimku in elektronskem
              naslovu, telefonska &scaron;tevilka, zgolj z va&scaron;o
              privolitvijo, ki jo podate ob posredovanju sporo&#269;ila prek
              kontaktnega obrazca na Spletni strani.
            </span>
          </p>
          <p class="c3">
            <span class="c0"></span>
          </p>
          <p class="c10 c17">
            <span class="c0">
              Posredovanje osebnih podatkov ni pogoj za uporabo storitev NYD,
              razen ko je to nujno potrebno za zagotavljanje teh storitev (npr.
              povratni stik NYD s posameznikom zahteva vnos vsaj enega
              kontaktnega podatka posameznika). Za uporabo storitve Posvet z
              zdravnikom nujno obdelujemo va&scaron;e osebne podatke,
              posredovane na podlagi obrazca za storitev Posvet z zdravnikom.
              &#268;e se z obdelavo osebnih podatkov, ki so nujno potrebni za
              izvajanje storitev, ne strinjate, izvedba zdravstvene storitve v
              sklopu uporabe Posvet z zdravnikom ni mogo&#269;a.
            </span>
          </p>
          <p class="c3">
            <span class="c0"></span>
          </p>
          <ol class="c1 lst-kix_list_6-0" start="2">
            <li class="c7 li-bullet-0">
              <span class="c5">Namen zbiranja podatkov</span>
            </li>
          </ol>
          <p class="c10 c17">
            <span class="c0">
              Osebne podatke pridobljene z uporabo kontaktnega obrazca na
              Spletni strani bo NYD obdeloval zgolj z namenom oblikovanja
              odgovora na posredovana vpra&scaron;anja, za verifikacijo
              upravi&#269;enosti do uporabe storitve, komentarje,
              povpra&scaron;evanja ali sporo&#269;ila in posredovanja tovrstnega
              odgovora na va&scaron; elektronski naslov/oziroma preko
              video/avdio/glasovnega/besedilnega sporo&#269;ila ter skladno s 3.
              &#269;lenom te politike zasebnosti. Vsi osebni podatki, ki nam jih
              posredujete, bodo obravnavni zaupno, s strani oseb, ki so se
              pogodbeno zavezale k varovanju osebnih podatkov.
            </span>
          </p>
          <p class="c3">
            <span class="c0"></span>
          </p>
          <p class="c10 c17">
            <span class="c0">
              &#268;e bo nastala potreba po nadaljnji obdelavi va&scaron;ih
              osebnih podatkov za drug namen, kot namen v katerega ste
              privolili, vas bomo o tem predhodno obvestili ter zaprosili za
              va&scaron;o predhodno pisno privolitev.
            </span>
          </p>
          <p class="c10 c14">
            <span class="c0"></span>
          </p>
          <ol class="c1 lst-kix_list_6-0" start="3">
            <li class="c7 li-bullet-0">
              <span class="c5">Hramba osebnih podatkov</span>
            </li>
          </ol>
          <p class="c10 c17">
            <span class="c0">
              NYD va&scaron;e osebne podatke hrani v skladu z enim izmed spodaj
              na&scaron;tetih obdobij hrambe:
            </span>
          </p>
          <ul class="c1 lst-kix_list_7-0 start">
            <li class="c10 c19 c24 li-bullet-0">
              <span class="c0">
                v primeru, ko se va&scaron;i podatki obdelujejo zgolj na podlagi
                va&scaron;e izrecne privolitve,{" "}
              </span>
              <span class="c5">do preklica privolitve</span>
              <span class="c0">
                &nbsp;k obdelavi osebnih podatkov z va&scaron;e strani,
              </span>
            </li>
            <li class="c10 c19 c24 li-bullet-0">
              <span class="c0">
                v primeru, ko je bil namen, za katerega so bili osebni podatki
                pridobljeni, &#382;e izpolnjen, pa pred tem ni pri&scaron;lo do
                preklica privolitve k obdelavi osebnih podatkov,{" "}
              </span>
              <span class="c5">do izpolnitve namena</span>
              <span class="c0">&nbsp;ali</span>
            </li>
            <li class="c10 c19 c24 li-bullet-0">
              <span class="c0">
                v kolikor je hramba osebnih podatkov potrebna za zavarovanje
                interesov NYD v zvezi z vsebino komunikacije, za&#269;ete preko
                kontaktnega obrazca,{" "}
              </span>
              <span class="c5">
                do poteka rokov za uveljavljanje va&scaron;ih pravic in
                obveznosti ali pravic in obveznosti NYD
              </span>
              <span class="c0">;</span>
            </li>
            <li class="c10 c19 c24 li-bullet-0">
              <span class="c0">
                v primeru, da ste na obrazcu za storitev prilo&#382;ili tudi
                va&scaron;e izvide, ali kakr&scaron;nekoli priloge se bodo le-te
                trajno izbrisale v roku 48 ur po zaklju&#269;eni oziroma
                opravljeni storitvi.
              </span>
            </li>
          </ul>
          <p class="c10 c19 c14">
            <span class="c0"></span>
          </p>
          <p class="c10 c17">
            <span class="c0">
              Po preteku obdobja hrambe osebnih podatkov bo NYD podatke trajno
              izbrisal ali anonimiziral, s tem pa osebnih podatkov ne bo
              ve&#269; mogo&#269;e povezati z vami.
            </span>
          </p>
          <p class="c10 c14 c19">
            <span class="c0"></span>
          </p>
          <ol class="c1 lst-kix_list_11-0" start="2">
            <li class="c6 li-bullet-0">
              <span class="c5">
                Osebni podatki zbrani z namestitvijo pi&scaron;kotkov
              </span>
            </li>
          </ol>
          <p class="c10">
            <span class="c11">
              Pi&scaron;kotki so name&scaron;&#269;eni, v skladu s politiko
              pi&scaron;kotkov, objavljeno na{" "}
            </span>
            <span>_____</span>
          </p>
          <p class="c10 c14">
            <span class="c0"></span>
          </p>
          <ol class="c1 lst-kix_list_11-0" start="3">
            <li class="c6 li-bullet-0">
              <span class="c5">Podatki o oceni o opravljeni storitvi</span>
            </li>
          </ol>
          <p class="c10 c14">
            <span class="c0"></span>
          </p>
          <p class="c10">
            <span class="c0">
              V primeru, video posveta lahko uporabnika pozovemo k oddaji ocene
              oz. mnenja o opravljeni storitvi po tem, ko bo storitev
              zaklju&#269;ena. V ta namen lahko skladno s to politiko zasebnosti
              hranimo uporabnikov elektronski naslov, termin, &#269;as trajanja
              posveta, &nbsp;opravljeno storitev in izbranega zdravstvenega
              sodelavca &ndash; v primeru da je ta podatek na voljo za namen in
              &#269;as izvedbe vpra&scaron;anja o zadovoljstvu po opravljeni
              storitvi.
            </span>
          </p>
          <p class="c10 c14">
            <span class="c0"></span>
          </p>
          <p class="c10">
            <span class="c0">
              Za namen oddaje ocene po opravljeni storitvi uporabnik spletnega
              mesta s potrditvijo Splo&scaron;nih pogojev potrjuje, da je
              seznanjen, da mu bo spletna stran po opravljeni storitvi ponudila
              oceno o zadovoljstvu na spletni strani. Uporabnik spletnega mesta
              se nato prostovoljno odlo&#269;i, ali bo na podal mnenje ali ne.
              Za namen analize vpra&scaron;alnika izvajalec obdeluje osebne
              podatke uporabnika. V primeru oddaje mnenja in ocene po opravljeni
              storitvi izvajalec obdeluje termin storitve (&#269;e je podatek na
              voljo) in informacije o storitvi, ki jo je uporabnik koristil. V
              primeru obdelave posebnih vrst osebnih podatkov za to pridobimo
              predhodno privolitev posameznika v skladu z veljavnimi predpisi s
              podro&#269;ja varstva osebnih podatkov.{" "}
            </span>
          </p>
          <p class="c10 c14">
            <span class="c5"></span>
          </p>
          <ol class="c1 lst-kix_list_11-0" start="4">
            <li class="c6 li-bullet-0">
              <span class="c5">
                Va&scaron;e pravice v zvezi z zbranimi osebnimi podatki
              </span>
            </li>
          </ol>
          <p class="c10">
            <span class="c0">
              Vsakdo ima, z namenom zagotoviti transparentno in zakonito
              obdelavo ter za&scaron;&#269;ititi svojo zasebnost, pravico
              dostopati do osebnih podatkov, pravico zahtevati popravo osebnih
              podatkov, pravico zahtevati izbris osebnih podatkov, pravico
              zahtevati omejitev obdelave osebnih podatkov, pravico do preklica
              privolitve k obdelavi osebnih podatkov, pravico do prenosljivosti
              osebnih podatkov in pravico prito&#382;iti se informacijskemu
              poobla&scaron;&#269;encu v zvezi z obdelavo njegovih osebnih
              podatkov, pod pogoji dolo&#269;enimi v veljavnih predpisih. Vse
              pravice so natan&#269;neje opisane spodaj.
            </span>
          </p>
          <p class="c10 c14">
            <span class="c0"></span>
          </p>
          <p class="c10">
            <span class="c0">
              Vse zahteve s katerimi &#382;elite uveljavljati katerokoli izmed
              zgoraj na&scaron;tetih pravic lahko posredujete na
              privacy@nyd.care . NYD si pridr&#382;uje pravico, da pred
              nadaljnjim postopanjem glede va&scaron;e zahteve preveri
              va&scaron;o identiteto.
            </span>
          </p>
          <p class="c10 c14">
            <span class="c0"></span>
          </p>
          <p class="c10">
            <span class="c0">
              &nbsp;Dru&scaron;tvo plju&#269;nih in alergijskih bolnikov
              Slovenije &ndash; DPABS je upravljavec podatkov za vso obdelavo
              osebnih podatkov, povezanih z vami. Vpra&scaron;anja in zahteve
              glede obdelave osebnih podatkov s strani podjetja Nyd lahko
              po&scaron;ljete podjetju Nyd ali na&scaron;emu uradniku za varstvo
              podatkov na naslov privacy@nyd.care ali v skladu z 9. to&#269;ko
              te politike zasebnosti. Na nas se lahko obrnete tudi preko zgoraj
              navedenega fizi&#269;nega naslova.
            </span>
          </p>
          <p class="c10 c14">
            <span class="c0"></span>
          </p>
          <ol class="c1 lst-kix_list_8-0 start" start="1">
            <li class="c7 li-bullet-0">
              <span class="c5">Pravica do dostopa do osebnih podatkov</span>
            </li>
          </ol>
          <p class="c10 c17">
            <span class="c11">V vsakem trenutku smete zahtevati</span>
            <span>&nbsp;</span>
            <span class="c0">
              potrditev, ali va&scaron;e osebne podatke obdelujemo. V primeru
              obdelovanja osebnih podatkov smete nadalje zahtevati informacije o
              namenu obdelave, vrsti osebnih podatkov, ki se obdelujejo, o
              osebah, ki va&scaron;e osebne podatke obdelujejo, o obdobjih
              hrambe oz. merilih za dolo&#269;itev obdobij hrambe, o obstoju
              pravice do popravka, pravice do izbrisa in pravice do omejitve
              obdelave osebnih podatkov, o pravici do ugovora zoper obdelavo
              osebnih podatkov, o pravici do vlo&#382;itve prito&#382;be pri
              nadzornem organu, o viru podatkov, &#269;e osebni podatki niso
              bili pridobljeni neposredno od vas in o morebitnem obstoju
              avtomatiziranega sprejemanja odlo&#269;itev.
            </span>
          </p>
          <p class="c3">
            <span class="c0"></span>
          </p>
          <ol class="c1 lst-kix_list_8-0" start="2">
            <li class="c7 li-bullet-0">
              <span class="c5">
                Pravica do poprave zbranih osebnih podatkov
              </span>
            </li>
          </ol>
          <p class="c10 c17">
            <span class="c0">
              V kolikor va&scaron;i osebni podatki, obdelovani s strani NYD,
              niso popolni ali pa so napa&#269;ni, smete zahtevati, da se
              tak&scaron;ni osebni podatki nemudoma popravijo ali dopolnijo.
            </span>
          </p>
          <p class="c10 c17">
            <span class="c0">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </p>
          <ol class="c1 lst-kix_list_8-0" start="3">
            <li class="c7 li-bullet-0">
              <span class="c5">Pravica do izbrisa osebnih podatkov</span>
            </li>
          </ol>
          <p class="c10 c17">
            <span class="c0">
              Izbris celote osebnih podatkov, ki so za obdelavo na voljo NYD, se
              sme zahtevati v naslednjih primerih:
            </span>
          </p>
          <ul class="c1 lst-kix_list_9-0 start">
            <li class="c9 li-bullet-0">
              <span class="c0">
                &#269;e je namen obdelave, za katerega so bili va&scaron;i
                osebni podatki zbrani, prenehal,
              </span>
            </li>
            <li class="c9 li-bullet-0">
              <span class="c0">
                &#269;e ste preklicali privolitev k obdelavi osebnih podatkov in
                za obdelavo ne obstaja nobena druga pravna podlaga,
              </span>
            </li>
            <li class="c9 li-bullet-0">
              <span class="c0">
                &#269;e ste obdelavi osebnih podatkov ugovarjali in so
                izpolnjeni vsi z zakonom dolo&#269;eni pogoji,
              </span>
            </li>
            <li class="c9 li-bullet-0">
              <span class="c0">
                &#269;e je NYD osebne podatke obdeloval na nezakonit na&#269;in
                ali
              </span>
            </li>
            <li class="c9 li-bullet-0">
              <span class="c0">
                &#269;e je izbris osebnih podatkov zahtevan v skladu z
                obveznostjo NYD.
              </span>
            </li>
          </ul>
          <p class="c10 c14 c22">
            <span class="c0"></span>
          </p>
          <ol class="c1 lst-kix_list_8-0" start="4">
            <li class="c7 li-bullet-0">
              <span class="c5">
                Pravica do omejitve obdelave osebnih podatkov
              </span>
            </li>
          </ol>
          <p class="c10 c17">
            <span class="c0">
              Obdelavo va&scaron;ih osebnih podatkov smete kadarkoli omejiti, v
              kolikor:
            </span>
          </p>
          <ul class="c1 lst-kix_list_10-0 start">
            <li class="c9 li-bullet-0">
              <span class="c0">
                podatki, ki se obdelujejo niso pravilni, nepravilnost podatkov
                pa se nana&scaron;a na obdobje, ki ga NYD kot obdelovalec
                podatkov lahko preveri,
              </span>
            </li>
            <li class="c9 li-bullet-0">
              <span class="c0">se podatki obdelujejo nezakonito, ali</span>
            </li>
            <li class="c22 c23 li-bullet-0">
              <span class="c0">
                jih potrebujete za uveljavljanje, izvajanje ali obrambo pravnih
                zahtevkov, NYD kot obdelovalec osebnih podatkov pa teh podatkov
                ne potrebuje ve&#269;, saj je namen obdelave prenehal.
              </span>
            </li>
          </ul>
          <p class="c10 c19 c14">
            <span class="c0"></span>
          </p>
          <ol class="c1 lst-kix_list_8-0" start="5">
            <li class="c7 li-bullet-0">
              <span class="c5">Pravica do preklica privolitve</span>
            </li>
          </ol>
          <p class="c10 c17">
            <span class="c0">
              Privolitev k obdelavi osebnih podatkov, ki ste jo dali skladno z
              drugo in/ali tretjo to&#269;ko te politike zasebnosti, lahko kadar
              koli prekli&#269;ete, ne da bi to vplivalo na zakonitost obdelave
              podatkov, ki se je na podlagi privolitve izvajala do njenega
              preklica.
            </span>
          </p>
          <p class="c10 c14">
            <span class="c0"></span>
          </p>
          <ol class="c1 lst-kix_list_8-0" start="6">
            <li class="c7 li-bullet-0">
              <span class="c5">Pravica do prenosljivosti osebnih podatkov</span>
            </li>
          </ol>
          <p class="c10 c17">
            <span class="c0">
              Svoje osebne podatke, ki se obdelujejo s strani NYD, smete
              kadarkoli zahtevati v strukturirani, splo&scaron;no uporabljani in
              strojno berljivi obliki. Tako pridobljene osebne podatke smete
              kadarkoli posredovati drugemu upravljavcu oziroma zahtevati, da se
              podatki posredujejo neposredno drugemu obdelovalcu.
            </span>
          </p>
          <p class="c3">
            <span class="c0"></span>
          </p>
          <ol class="c1 lst-kix_list_8-0" start="7">
            <li class="c7 li-bullet-0">
              <span class="c5">
                Pravica do prito&#382;be Informacijskemu
                poobla&scaron;&#269;encu
              </span>
            </li>
          </ol>
          <p class="c10 c17">
            <span class="c0">
              V kolikor menite, da NYD z va&scaron;imi zbranimi osebnimi podatki
              ne ravna na na&#269;in predpisan z relevantnimi zakonskimi
              dolo&#269;bami, lahko podate prito&#382;bo Informacijskemu
              poobla&scaron;&#269;encu (Dunajska cesta 22, 1000 Ljubljana,
              elektronski naslov: gp.ip@ip-rs.si telefon: 012309730, spletna
              stran:{" "}
            </span>
            <span class="c30 c11 c31">
              <a
                class="c29"
                href="https://www.google.com/url?q=http://www.ip-rs.si&amp;sa=D&amp;source=editors&amp;ust=1630925492962000&amp;usg=AOvVaw23fqhih1-Boju2UcQvamSc"
              >
                www.ip-rs.si
              </a>
            </span>
            <span class="c0">).</span>
          </p>
          <p class="c3">
            <span class="c0"></span>
          </p>
          <ol class="c1 lst-kix_list_11-0" start="5">
            <li class="c6 li-bullet-0">
              <span class="c5">Kdo obdeluje osebne podatke?</span>
            </li>
          </ol>
          <p class="c4">
            <span class="c0">
              Uporabljamo tehni&#269;ne in organizacijske varnostne ukrepe za
              za&scaron;&#269;ito osebnih podatkov pred nezakonitim ali
              nepoobla&scaron;&#269;enim dostopom ali uporabo, pa tudi pred
              nenamerno izgubo ali okrnitvijo njihove celovitosti. Oblikovali
              smo jih ob upo&scaron;tevanju svoje IT infrastrukture,
              mo&#382;nega vpliva na zasebnost posameznika v skladu s trenutnimi
              industrijskimi standardi in prakso. Na&scaron;i pogodbeni
              podobdelovalci bodo obdelovali va&scaron;e osebne podatke samo,
              &#269;e bodo upo&scaron;tevali te tehni&#269;ne in organizacijske
              varnostne ukrepe.
            </span>
          </p>
          <p class="c4">
            <span class="c0">
              Ohranjanje varnosti podatkov pomeni varovanje zaupnosti,
              celovitosti in razpolo&#382;ljivosti osebnih podatkov:
            </span>
          </p>
          <ul class="c1 lst-kix_list_10-0">
            <li class="c4 c22 c24 li-bullet-0">
              <span class="c0">
                zaupnost in celovitost: osebne podatke posameznikov bomo
                za&scaron;&#269;itili pred nedovoljeno ali nezakonito obdelavo
                ter pred nenamerno izgubo, uni&#269;enjem ali po&scaron;kodbo;
              </span>
            </li>
            <li class="c9 li-bullet-0">
              <span class="c0">
                razpolo&#382;ljivost: zagotovili bomo, da lahko
                poobla&scaron;&#269;eni obdelovalci dostopajo do osebnih
                podatkov, zgolj in samo kadar je to potrebno.
              </span>
            </li>
          </ul>
          <p class="c4">
            <span class="c0">
              Na&scaron;i varnostni postopki vklju&#269;ujejo: varnost dostopa,
              varnostne kopije, spremljanje, pregled in vzdr&#382;evanje,
              upravljanje varnostnih incidentov itd. Glede na namene, za katere
              obdelujemo osebne podatke posameznikov, le-te lahko razkrijemo
              naslednjim kategorijam obdelovalcev:
            </span>
          </p>
          <p class="c4">
            <span class="c0">a) Znotraj podjetja: zaposleni v podjetju.</span>
          </p>
          <p class="c4">
            <span class="c0">
              b) Na&scaron;im poslovnim partnerjem, od katerih zahtevamo, da
              vedno ravnajo v skladu z veljavnimi zakoni, politiko varstva
              osebnih podatkov ter posve&#269;ajo veliko pozornosti zaupnosti
              va&scaron;ih osebnih podatkov:
            </span>
          </p>
          <p class="c4">
            <span class="c0">
              - fizi&#269;ne in pravne osebe, ki so na&scaron;i pogodbeni
              partnerji in za katere smo pridobili privolitev upravljalavca ter
              za obdelovalca izvajajo svetovanja ali posamezne storitve z
              namenom izvr&scaron;evanja pogodbenega razmerja;
            </span>
          </p>
          <p class="c4">
            <span class="c0">
              c) Drugim tretjim osebam: Kadar to zahteva zakon ali je zakonito
              potrebno za za&scaron;&#269;ito:
            </span>
          </p>
          <p class="c4">
            <span class="c0">
              - podjetja (skladnost z zakoni, zahteve organov, sodne odredbe,
              pravni postopki, obveznosti, povezane s poro&#269;anjem in
              vlaganjem informacij organom itd.)
            </span>
          </p>
          <p class="c4">
            <span class="c0">
              - &nbsp;preverjanje ali uveljavljanje skladnosti s politiko in
              sporazumi podjetja;
            </span>
          </p>
          <p class="c4">
            <span class="c0">
              Na&scaron;i poslovni partnerji, navedeni zgoraj pod to&#269;ko b),
              smejo osebne podatke posameznikov obdelovati zgolj v okviru
              na&scaron;ih navodil in osebnih podatkov ne smejo uporabiti za
              zasledovanje kakr&scaron;nihkoli lastnih interesov. Vsak
              posameznik pa mora upo&scaron;tevati, da podobdelovalci, navedeni
              v zgornjih to&#269;kah b) in c), zlasti ponudniki storitev, ki vam
              lahko ponujajo storitve v okviru in/ali aplikacij ali preko
              lastnih kanalov, lahko lo&#269;eno zbirajo va&scaron;e osebne
              podatke. V takem primeru so izklju&#269;no odgovorni za njihov
              nadzor, sodelovanje posameznikov z njimi pa mora potekati skladno
              z njihovimi pogoji.
            </span>
          </p>
          <p class="c4">
            <span class="c0">d) Informacije, ki jih delimo z drugimi</span>
          </p>
          <p class="c4">
            <span class="c0">
              Podatki, ki vas identificirajo: Osebne podatke, iz katerih vas
              lahko prepoznamo, bomo delili le v dolo&#269;enih omejenih
              okoli&scaron;&#269;inah, kot je opisano spodaj.
            </span>
          </p>
          <p class="c4">
            <span class="c18">5.1.</span>
            <span class="c0">
              &nbsp;Zdravniki, zdravstveni sodelavci &ndash; zdravstvene podatke
              o vas, lahko delimo z zdravniki (vsi so registrirani pri
              Zdravni&scaron;ki zbornici), ki bodo ocenili va&scaron;e
              zdravstveno stanje. V primeru, da podate eksplicitno soglasje
              lahko va&scaron;e zdravstvene podatke delimo z zdravniki in
              zdravstvenimi sodelavci, da jim omogo&#269;imo, da bolje ocenijo
              zdravstveno stanje, vam svetujejo in zagotovijo storitve, ki jih
              zahtevate (v skladu z na&scaron;imi pogoji).{" "}
            </span>
          </p>
          <p class="c4">
            <span class="c0">
              Upo&scaron;tevajte, da dr&#382;ave zunaj EGP morda ne bodo nudile
              enake ravni varstva podatkov, &#269;eprav bo na&scaron;e zbiranje,
              shranjevanje in uporaba va&scaron;ih osebnih podatkov &scaron;e
              naprej urejala ta politika zasebnosti. Zagotavljamo, da so vsi
              podatki, posredovani zdravnikom, za&scaron;&#269;iteni z
              ustreznimi in ustreznimi za&scaron;&#269;itnimi ukrepi, vsi
              zdravniki pa so vezani na pogodbene obveznosti, ki
              vklju&#269;ujejo vzor&#269;ne klavzule Evropske komisije, da vsem
              zdravnikom zagotovijo, da osebne podatke, ki jih prejmejo, hranijo
              varne, zaupne in jih uporabljajo le za namene, za katere so jim na
              voljo.
            </span>
          </p>
          <p class="c4">
            <span class="c18">5.2.</span>
            <span class="c0">
              &nbsp;Z Dru&scaron;tvo plju&#269;nih in alergijskih bolnikov
              Slovenije &ndash; DPABS kot upravljavcu osebnih podatkov, se
              delijo vsi osebni podatki, ki so zbrani na podlagi va&scaron;ega
              soglasja, razen podatkov o zdravstvenih kartotekah. Dru&scaron;tvo
              plju&#269;nih in alergijskih bolnikov Slovenije &ndash; DPABS
              lahko deli podatke z NYD pri uporabi spletne strani, v skladu z
              njihovo politiko zasebnosti in splo&scaron;nimi pogoji. &#268;e se
              bo polo&#382;aj spremenil, vas bomo vpra&scaron;ali, preden bomo
              podatke delili na ta na&#269;in, razen &#269;e ste svoje soglasje
              &#382;e navedli neposredno pri Dru&scaron;tvo plju&#269;nih in
              alergijskih bolnikov Slovenije &ndash; DPABS.
            </span>
          </p>
          <p class="c4">
            <span class="c18">5.3.</span>
            <span class="c0">
              &nbsp;Ponudniki sporo&#269;il in komunikacija z uporabnikom
            </span>
          </p>
          <p class="c4">
            <span class="c0">
              Nekateri podatki iz komunikacij s telefonom, videom ali
              besedilnimi sporo&#269;ili se lahko za&#269;asno prenesejo in
              shranijo na stre&#382;nike zunaj EGP.
            </span>
          </p>
          <p class="c4">
            <span class="c11">
              Twilio, na&scaron; ponudnik videa, glasovnih in besedilnih ter SMS
              sporo&#269;il, ima sede&#382; v ZDA. Twilio je organizacija,
              skladna z GDPR, registrirana z Privacy Shield in ISO 27001. Twilio
              je po pogodbi vezan na podjetje NYD d.o.o., ki vsebuje standardne
              pogodbene klavzule EU za GDPR. Ve&#269; informacij najdete na
              spletnem mestu{" "}
            </span>
            <span class="c11">Twilio.</span>
            <sup>
              <a href="#cmnt2" id="cmnt_ref2">
                [b]
              </a>
            </sup>
          </p>
          <p class="c4">
            <span class="c11">
              Podizvajalec in posledi&#269;no izvajalec ne jam&#269;ita za
              nedostavljena sporo&#269;ila zaradi izklopljenega ali
              nedosegljivega mobilnega telefona za ve&#269; kot 72 ur oz. za
              nastavljeni &#269;as veljavnosti poslanega sporo&#269;ila. Za
              po&scaron;iljanje avtomatiziranih sporo&#269;il SMS uporabljamo
              storitev ponudnika{" "}
            </span>
            <span class="c11">Twilio.</span>
            <sup>
              <a href="#cmnt3" id="cmnt_ref3">
                [c]
              </a>
            </sup>
          </p>
          <p class="c4">
            <span class="c0">
              S ponudnikom imamo sklenjeno pogodbo o obdelavi osebnih podatkov.
              NYD daje ponudniku po&scaron;iljanja sporo&#269;il SMS pravico do
              uporabe, shranjevanja in reproduciranja sporo&#269;il SMS le za
              namen zagotavljanja poti za po&scaron;iljanje naslovnikom.
            </span>
          </p>
          <p class="c4">
            <span class="c11">
              Amazon AWS, na&scaron; ponudnik elektronskih in besedilnih
              sporo&#269;il, ima sede&#382; v ZDA. Amazon AWS je organizacija,
              skladna z GDPR, registrirana z Privacy Shield in ISO ISO/IEC
              27001:2013, 27017:2015, 27018:2019, and ISO/IEC 9001:2015 and CSA
              STAR CCM v3.0.1. Amazon AWS je po pogodbi vezan na podjetje NYD
              d.o.o., ki vsebuje standardne pogodbene klavzule EU za GDPR.
              Ve&#269; informacij najdete na spletnem mestu Amazon AWS
            </span>
            <span class="c11">.</span>
            <sup>
              <a href="#cmnt4" id="cmnt_ref4">
                [d]
              </a>
            </sup>
          </p>
          <p class="c4">
            <span class="c0">
              Storitvi elektronskega obve&scaron;&#269;anja preko sporo&#269;il
              SMS in elektronske po&scaron;te se izvajata prek spletne
              aplikacije ali vmesnika, ki ga ponuja izvajal&#269;ev
              podizvajalec. Pred obve&scaron;&#269;anjem bo NYD od uporabnika
              kot prejemnika pridobil jasno, nedvoumno in s Splo&scaron;no
              uredbo o varstvu podatkov skladno privolitev za prejem tovrstnih
              sporo&#269;il. Sporo&#269;ila SMS in elektronska po&scaron;ta so
              posredovani v zunanji sistem,v skladu s to politiko zasebnosti.
            </span>
          </p>
          <p class="c4">
            <span class="c0">
              Podizvajalec in posledi&#269;no izvajalec ne jam&#269;ita za
              nedostavljena elektronska sporo&#269;ila zaradi za&#269;asnih ali
              trajnih blokad po&scaron;tnega stre&#382;nika prejemnika, ki so
              lahko posledica predhodno prejetih elektronskih sporo&#269;il
              drugih ponudnikov, ki uporabljajo isti IP naslov ali sorodnega IP
              naslovnega prostora, ki bi lahko povzro&#269;il blokado poslanega
              sporo&#269;ila.
            </span>
          </p>
          <p class="c4 c14">
            <span class="c0"></span>
          </p>
          <ol class="c1 lst-kix_list_11-0" start="6">
            <li class="c4 c16 li-bullet-0">
              <span class="c5">NYD kot obdelovalec podatkov</span>
            </li>
          </ol>
          <p class="c4 c14">
            <span class="c5"></span>
          </p>
          <p class="c10">
            <span class="c11">
              Kot del zagotavljanja spletne strani mora NYD za&#269;asno
              obdelati podatke, ki lahko predstavljajo osebne podatke o
              uporabnikih, vklju&#269;no s &scaron;ifriranimi video datotekami,
              telefonsko &scaron;tevilko, elektronskim naslovom in naslovom IP.
              NYD do teh podatkov po koncu posvetovanj ne dostopa, in ne
              shranjuje. Kljub temu hranimo dnevnike posvetovanj, vklju&#269;no
              s &#269;asovnimi &#382;igi.{" "}
            </span>
            <span class="c20">&nbsp;</span>
          </p>
          <p class="c4">
            <span class="c0">
              Dru&scaron;tvo plju&#269;nih in alergijskih bolnikov Slovenije
              &ndash; DPABS je v vlogi upravljavca podatkov za obdelavo, ki
              poteka v okviru posvetovanja, uporabi Posvet z zdravnikom storitev
              in komuniciranja z uporabniki na spletni strani, kjer je
              Dru&scaron;tvo plju&#269;nih in alergijskih bolnikov Slovenije
              &ndash; DPABS odgovorna za to, da NYD lahko zakonito obdeluje take
              osebne podatke (v vlogi obdelovalca), vklju&#269;no z, vendar ne
              omejeno na, obve&scaron;&#269;anjem uporabnikov o tak&scaron;ni
              obdelavi, njihovih pravicah in &nbsp;kontaktnih podatkih ter
              pridobitvijo vseh potrebnih privolitev in / ali pooblastil za
              zbiranje in uporabo takih osebnih podatkov prek spletne strani.
              NYD v imenu Dru&scaron;tvo plju&#269;nih in alergijskih bolnikov
              Slovenije &ndash; DPABS obdeluje osebne podatke v skladu z
              medsebojno pogodbo med NYD in Dru&scaron;tvo plju&#269;nih in
              alergijskih bolnikov Slovenije &ndash; DPABS obdelavi podatkov,
              prilo&#382;eno dolo&#269;ilom krovne pogodbe.
            </span>
          </p>
          <p class="c4 c14">
            <span class="c0"></span>
          </p>
          <ol class="c1 lst-kix_list_11-0" start="7">
            <li class="c4 c16 li-bullet-0">
              <span class="c5">Sprememba politike zasebnosti</span>
            </li>
          </ol>
          <p class="c4">
            <span class="c0">
              NYD si pridr&#382;uje pravico kadarkoli spremeniti vsebino te
              politike zasebnosti. Spremenjena politika zasebnosti velja od
              trenutka objave na Spletni strani in ureja nadaljnjo obdelavo
              &#382;e zbranih osebnih podatkov in obdelavo osebnih podatkov,
              zbranih po za&#269;etku veljavnosti spremenjene politike
              zasebnosti. Priporo&#269;amo, da pred vsakim posredovanjem osebnih
              podatkov preverite aktualno razli&#269;ico politike zasebnosti, da
              boste seznanjeni z morebitnimi spremembami in dopolnitvami.{" "}
            </span>
          </p>
          <p class="c4">
            <span class="c0">
              V kolikor bo pri&scaron;lo do bistvene spremembe te politike
              zasebnosti (npr. sprememba namena obdelave ali sprememba kategorij
              osebnih podatkov, ki se zbirajo), vas bomo o spremembi obvestili,
              hkrati pa zahtevali ponovno privolitev k obdelavi osebnih
              podatkov.
            </span>
          </p>
          <p class="c4">
            <span class="c0">
              Datum zadnje spremembe te politike zasebnosti je objavljen spodaj.
            </span>
          </p>
          <p class="c4 c14">
            <span class="c5"></span>
          </p>
          <ol class="c1 lst-kix_list_11-0" start="8">
            <li class="c6 li-bullet-0">
              <span class="c5">
                Poobla&scaron;&#269;ena oseba za varstvo osebnih podatkov{" "}
              </span>
            </li>
          </ol>
          <p class="c4">
            <span class="c0">
              Za nadzor nad skladnostjo s Politiko zasebnosti smo imenovali
              poobla&scaron;&#269;eno osebo za varstvo osebnih podatkov (DPO).
              &#268;e imate kakr&scaron;no koli vpra&scaron;anje v zvezi s to
              Politiko zasebnosti ali o tem, kako ravnamo z va&scaron;imi
              osebnimi podatki, se obrnite na poobla&scaron;&#269;eno osebo za
              varstvo osebnih podatkov pri NYD, Ma&scaron;o Rupnik,
              univ.dipl.prav.
            </span>
          </p>
          <p class="c10 c14">
            <span class="c0"></span>
          </p>
          <ol class="c1 lst-kix_list_11-0" start="9">
            <li class="c6 li-bullet-0">
              <span class="c5">Vpra&scaron;anja in zahteve</span>
            </li>
          </ol>
          <p class="c4">
            <span class="c0">
              V primeru kakr&scaron;nihkoli vpra&scaron;anj in zahtev nas prosim
              kontaktirajte na info@nyd.care.{" "}
            </span>
          </p>
          <p class="c3">
            <span class="c0"></span>
          </p>
          <p class="c4">
            <span class="c11 c27">&copy;</span>
            <span class="c0">NYD, Ljubljana, september 2021.</span>
          </p>
          <div>
            <p class="c14 c26">
              <span class="c21"></span>
            </p>
            <p class="c15 c28 c14">
              <span class="c21"></span>
            </p>
          </div>
        </body>
      </div>
    </div>
  );
}
