import React from 'react';

import axios from 'axios';
// import list from '../../zavarovanci.json'

// const arrayToPush = [];

// list.map((item) => {
// 	arrayToPush.push({
// 			taxNumber : item,
// 			policyNumber : item,
// 			policyType : "Insurance",
// 			endDate : "2022-01-01T00:00:00.000Z",
// 			policyIssuer : "Merkur zavarovalnica d.d."

// 	})
// })


// const onSend = () => {
// 	axios.post('${process.env.REACT_APP_API_URL}policies/multiple',{
// 	multiplePolicies: arrayToPush
// })
// }
// console.log(arrayToPush);



export default function SplosniPogojiUporabe() {
	import('../App.css');

  return (
    <div className="containrforPrivacyPolicy">
      <p class="c19">
        <span class="c1">SPLO&Scaron;NI POGOJI UPORABE SPLETNE STRANI </span>
      </p>
      <p class="c5">
        <span class="c1"></span>
      </p>
      <p class="c3">
        <span class="c4">
          Ti Splo&scaron;ni pogoji uporabe spletne strani (&raquo;Splo&scaron;ni
          pogoji uporabe&laquo;) urejajo razmerje med vami in NYD d.o.o., z
          registriranim naslovom in poslovnim naslovom na Pre&scaron;ernova
          cesta 19, 1000 Ljubljana (&raquo;mi&laquo;, &raquo;nas&laquo;,
          &raquo;NYD&laquo;) v zvezi z uporabo spletne strani
        </span>
        <span>&nbsp;https://izkasljaj.nyd.care/</span>
        <span class="c4">&nbsp;</span>
        <span class="c4">in </span>
        <sup>
          <a href="#cmnt1" id="cmnt_ref1">
            [a]
          </a>
        </sup>
        <span class="c0">
          vseh morebitnih podstrani te spletne strani (&raquo;Spletna
          stran&laquo;).
        </span>
      </p>
      <p class="c3">
        <span class="c0">
          Poleg Splo&scaron;nih pogojev uporabe spletne strani napro&scaron;amo,
          da se seznanite tudi s Politiko zasebnosti, ki opredeljuje na&#269;in
          zbiranja, uporabo in namene zbiranja va&scaron;ih podatkov, in s
          Politiko uporabe pi&scaron;kotkov, ki razkriva katere pi&scaron;kotke
          zbiramo ter za kak&scaron;ne namene.
        </span>
      </p>
      <p class="c3">
        <span class="c0">
          Z DOSTOPANJEM DO SPLETNE STRANI IN NJENO UPORABO SOGLA&Scaron;ATE S
          TEMI SPLO&Scaron;NIMI POGOJI UPORABE, KI VAS ZAVEZUJEJO OB VSAKOKRATNI
          UPORABI SPLETNE STRANI. V KOLIKOR S TEMI SPLO&Scaron;NIMI POGOJI
          UPORABE NE SOGLA&Scaron;ATE, DO SPLETNE STRANI NE DOSTOPAJTE OZIROMA
          JE NE UPORABLJAJTE.
        </span>
      </p>
      <p class="c3">
        <span class="c0">
          Posvet z zdravnikom je storitev za uporabnike spletnega mesta (v
          nadaljevanju: &raquo;uporabnik&laquo;) kot SaaS posredni&scaron;ka
          storitev, ki omogo&#269;a rezervacijo terminov, komunikacijo z
          uporabniki preko elektronske po&scaron;te ali SMS obvestil.
          &nbsp;Posvet z zdravnikom je posredni&scaron;ka storitev med vami kot
          uporabnikom (&raquo;vi&laquo;, &raquo;vas&laquo;,&laquo;
          uporabnik&laquo;) in ponudnikom oziroma izvajalcem zdravstvenih
          storitev, zato NYD ne bi smeli obravnavati kot ponudnika zdravstvenih
          storitev. Sporazum med vami in NYD d.o.o. je torej strogo pogodba o
          storitvah, ki se nana&scaron;a na zagotavljanje tehni&#269;ne
          re&scaron;itve. NYD d.o.o. in Dru&scaron;tvo plju&#269;nih in
          alergijskih bolnikov Slovenije &ndash; DPABS kot naro&#269;nik, ima
          sklenjeno pogodbo o poslovnem sodelovanju za omogo&#269;anje storitve
          Posvet z zdravnikom, ki omogo&#269;a virtualno oskrbo uporabnikov z
          zvo&#269;no in/ali video obravnavo s strani zdravnika specialista
          splo&scaron;ne oz. dru&#382;inske medicine (zdravstvena storitev, ki
          se izvaja v sklopu uporabe spletne strani), podpora razli&#269;nim
          tipom mo&#382;nih zaklju&#269;kov obravnave v sklopu zdravstvene
          storitve, skladno s politiko Dru&scaron;tvo plju&#269;nih in
          alergijskih bolnikov Slovenije &ndash; DPABS storitve, mo&#382;nost
          oddaje ocene zadovoljstva z opravljeno storitvijo. NYD d.o.o. in
          Dru&scaron;tvo plju&#269;nih in alergijskih bolnikov Slovenije &ndash;
          DPABS nista in ne moreta biti odgovorna za vrsto ali kakovost
          nasvetov, pregledov ali drugih zdravstvenih storitev, ki/&#269;e se
          zagotavljajo v okviru video sestanka oziroma obravnave.
        </span>
      </p>
      <p class="c2">
        <span class="c0"></span>
      </p>
      <p class="c3">
        <span class="c1">1. Vsebina Spletne strani in storitve</span>
      </p>
      <p class="c3">
        <span class="c0">
          Namen Spletne strani je zagotoviti informacije o in v zvezi z NYD in
          storitvami, ki jih NYD zagotavlja. Vsa na Spletni strani objavljena
          vsebina in podajanje nasvetov so zgolj informativnega zna&#269;aja in
          ne predstavlja zdravni&scaron;kega nasveta in/ali dejavnosti
        </span>
      </p>
      <p class="c3">
        <span class="c0">
          Navkljub skrbi za pravilnost, a&#382;urnost in celovitost vsebine
          Spletne strani, se ob&#269;asni nepravilnosti, zastarelosti ali
          pomanjklivosti informacij, objavljenih na Spletni strani ne gre
          izogniti. NYD ne prevzema odgovornosti za &scaron;kodo, nastalo zaradi
          objave ali kot posledica uporabe takih nepravilnih, zastarelih ali
          pomanjkljivih informacij. NYD ne jam&#269;i nemotenega delovanja in
          dostopanja do Spletne strani ter ne prevzema odgovornosti za
          &scaron;kodo nastalo kot posledica oviranega delovanja oziroma
          nezmo&#382;nosti dostopanja do Spletne strani. Informacije, objavljene
          na Spletni strani, so izklju&#269;no informativne narave. NYD ne
          prevzema nobene odgovornosti, da so te informacije popolne, vsebinsko
          pravilne in sve&#382;e.{" "}
        </span>
      </p>
      <p class="c11">
        <span class="c0">
          Z namenom kar najbolj sprotnega posodabljanja vsebine Spletne strani
          si NYD pridr&#382;uje pravico kadarkoli spremeniti in/ali posodobiti
          vsebino Spletne strani. Prav tako si NYD pridr&#382;uje pravico
          kadarkoli s spleta popolnoma odstraniti Spletno stran in/ali vsem
          uporabnikom onemogo&#269;iti njeno uporabo.
        </span>
      </p>
      <p class="c11">
        <span class="c0">
          Vsebina, ki jo NYD objavlja na Spletni strani in v obliki nasvetov
          preko Spletne stranii, naj bi &scaron;tela kot dopolnilo medicinskim
          napotkom zdravnika. Uporaba Spletne strani in storitev je zgolj
          nadomestek tradicionalnega zdravstvenega varstva in je namenjena kot
          dodatno orodje zdravnikom, ki uporabnikom zagotavljajo u&#269;inkovito
          zdravstveno varstvo. NYD v nobenem pogledu ne prevzema odgovornosti
          kot izvajalec zdravstvene dejavnosti.{" "}
        </span>
      </p>
      <p class="c11" id="h.gjdgxs">
        <span class="c0">
          &#268;e zdravnik, zdravstveni delavec oziroma partner na podlagi
          informacij, prejetih s strani uporabnika, oceni, da bi lahko
          &scaron;lo za neposredno &#382;ivljenjsko ogro&#382;enost ali potrebo
          po nujni medicinski pomo&#269;i, usmeri uporabnika na pristojno
          zdravstveno slu&#382;bo ali na telefonsko &scaron;tevilko 112.
          Uporabnik &nbsp;je v tak&scaron;nem primeru v celoti sam odgovoren za
          ustrezno ukrepanje. Za strokovnost in kakovost nudenja nasveta
          uporabniku je v celoti odgovoren zdravstveni delavec. Nasveti in
          storitve temeljijo na postavljenem vpra&scaron;anju uporabnika in
          informacijah o simptomih, ki jih uporabnik v povezavi z te&#382;avo
          navede. Tako opravljeni nasveti ne predstavljajo postavitve diagnoze
          ali zdravni&scaron;kega mnenja in dejavnosti.
        </span>
      </p>
      <p class="c11">
        <span class="c0">
          NYD zagotovalja uporabniku uporabo storitev ob podpisu pristopnega
          obrazca k storitvi (v nadaljevanju: &raquo;Pristopni obrazec&laquo;)
          in ob izpolnjevanju obveznosti teh Splo&scaron;nih pogojev. NYD ponuja
          spletno aplikacijo, do katere se lahko dostopa preko internetne
          povezave s komercialno dosegljivimi napravami (ra&#269;unalniki,
          mobilnimi telefoni, tablicami) in programi (spletni brskalniki). NYD
          ni dol&#382;an zagotavljati naprav, programov in internetnega dostopa,
          ki so potrebni za uporabo storitev.
        </span>
      </p>
      <p class="c3">
        <span class="c1">2. Dostop do in uporaba Spletne strani</span>
      </p>
      <p class="c3">
        <span class="c0">
          Dostop do in uporaba Spletne strani je dopustna le v skladu in pod
          pogoji, ki jih dolo&#269;ajo ti Splo&scaron;ni pogoji uporabe,
          na&scaron;a politika zasebnosti, politika uporabe pi&scaron;kotkov in
          druge relevantne dolo&#269;be uporabnega prava.
        </span>
      </p>
      <p class="c3">
        <span class="c0">
          Z dostopom do in uporabo Spletne strani jam&#269;ite, da (i)
          na&#269;in va&scaron;e uporabe Spletne strani ne bo &scaron;kodoval
          Spletni strani, drugim obiskovalcem Spletne strani ali tretjim osebam,
          (ii) ne boste posku&scaron;ali pridobiti neavtoriziranega dostopa do
          spletne strani, (iii) ne boste posku&scaron;ali preslikati Spletne
          strani na katerokoli drugo spletno stran, (iv) boste do Spletne strani
          dostopali le prek vmesnika zagotovljenega z na&scaron;e strani, (v) ne
          boste uporabljali programske opreme, ki omogo&#269;a pridobivanje
          informacij o uporabi Spletne strani s strani drugih obiskovalcev ter
          ugotavljanje njihove identitete, (vi) Spletne strani ne boste
          uporabljali v nasprotju s temi Splo&scaron;nimi pogoji uporabe in
          drugimi relevantnimi dolo&#269;bami uporabnega prava.
        </span>
      </p>
      <p class="c3">
        <span class="c15">3. Vsebina uporabnikov/strank</span>
      </p>
      <p class="c3">
        <span class="c0">
          Vsa vsebina, ki jo posredujete NYD, vklju&#269;no z informacijami in
          gradivom posredovanim v okviru va&scaron;ih vpra&scaron;anj,
          odgovorov, pro&scaron;enj za posredovanje informacij in komentarjev (v
          nadaljevanju &ldquo;Vsebina uporabnika&rdquo;) je va&scaron;a
          izklju&#269;na odgovornosti in za njo ne odgovarjamo. Uporabnik je
          izklju&#269;no odgovoren za podatke, ki jih posreduje v aplikaciji ali
          prek nje oziroma preko video klica in posledi&#269;no prevzema vso
          odgovornost za svojo vsebino.
        </span>
      </p>
      <p class="c3">
        <span class="c0">
          Sogla&scaron;ate in potrjujete, da ste v celoti odgovorni za vso
          vsebino, ki nam jo posredujete. NYD ne nadzira Vsebine uporabnikov in
          ne jam&#269;i za njeno natan&#269;nost, celovitost in kakovost.
          Sogla&scaron;ate da NYD&nbsp;ni odgovoren za kakr&scaron;no koli
          izgubo ali &scaron;kodo, ki bi jo lahko imelo va&scaron;e
          zana&scaron;anje na te informacije.
        </span>
      </p>
      <p class="c3">
        <span class="c4">
          O domnevnih kr&scaron;itvah v povezavi z Vsebino uporabnika nas
          prosimo obvestite na elektronski naslov:{" "}
        </span>
        <span class="c4 c12">
          <a class="c13" href="mailto:info@nyd.care">
            info@nyd.care
          </a>
        </span>
        <span class="c0">
          . Vsebina, ki nam jo posredujete bo prebrana, uporabljena in zbrana
          samo s stran NYD ali s strani NYD poobla&scaron;&#269;enih oseb.
        </span>
      </p>
      <p class="c3">
        <span class="c1">4. Povezave do spletnih strani tretjih oseb</span>
      </p>
      <p class="c3">
        <span class="c0">
          Spletna stran lahko, z namenom olaj&scaron;anja iskanja in
          izbolj&scaron;anja uporabni&scaron;ke izku&scaron;nje, vsebuje
          hiperpovezave do spletnih strani tretjih oseb. Sama objava
          hiperpovezave na Spletni strani ne predstavlja, niti ne nakazuje na
          kakr&scaron;nokoli obliko podpore, sponzorstva ali priporo&#269;ila s
          hiperpovezavo dostopane spletne strani, njene vsebine ali lastnika, s
          strani NYD.
        </span>
      </p>
      <p class="c3">
        <span class="c0">
          S klikom na hiperpovezavo boste zapustili to Spletno stran in bili
          preusmerjeni na spletno stran tretje osebe za katero veljajo drugi
          splo&scaron;ni pogoji uporabe in politika zasebnosti.{" "}
        </span>
      </p>
      <p class="c3">
        <span class="c0">
          NYD ne prevzema odgovornosti za tako dostopane spletne strani tretjih
          oseb, za vsebino in informacije objavljene na njih, niti ne prevzema
          odgovornosti za uspe&scaron;no dostopanje do tovrstnih spletnih
          strani.{" "}
        </span>
      </p>
      <p class="c3">
        <span class="c1">5. Podatki o oceni o opravljeni storitvi</span>
      </p>
      <p class="c3">
        <span class="c0">
          V primeru, &nbsp;da se uporabnik eksplicitno strinja (aktivno odda
          strinjanje) ob zaklju&#269;ku procesa oddaje Pristopnega obrazeca
          oziroma povpra&scaron;evanja za samopla&#269;ni&scaron;ko zdravstveno
          storitev ali sorodnega procesa na spletni strani, lahko uporabnika
          pozovemo k oddaji ocene oz. mnenja o opravljeni storitvi po tem, ko bo
          storitev zaklju&#269;ena. V ta namen lahko skladno s temi
          splo&scaron;nimi pogoji in politiko zasebnosti hranimo uporabnikov
          elektronski naslov, termin (v kolikor je ta na voljo) in izbranega
          zdravstvenega sodelavca za namene analize o zadovoljstvu po opravljeni
          storitvi.
        </span>
      </p>
      <p class="c3">
        <span class="c0">
          Za namen oddaje mnenja in ocene po opravljeni storitvi uporabnik
          spletnega mesta s potrditvijo Splo&scaron;nih pogojev potrjuje, da je
          seznanjen s temi splo&scaron;nimi pogoji in politiko zasebnosti.
          Uporabnik spletnega mesta se prostovoljno odlo&#269;i, ali bo na
          vpra&scaron;anja odgovarjal in podal mnenje, ali ne. Za namen
          po&scaron;iljanja vpra&scaron;alnika uporabniku po elektronski
          po&scaron;ti izvajalec obdeluje osebne podatke posameznika, in sicer
          njegov elektronski naslov. V primeru odgovarjanja na vpra&scaron;anja
          in oddaje mnenja in ocene po opravljeni storitvi izvajalec obdeluje
          elektronski naslov uporabnika, termin storitve (&#269;e je podatek na
          voljo) in informacije o zdravstveni storitvi, ki jo je uporabnik
          koristil. V primeru obdelave posebnih vrst osebnih podatkov za to
          pridobimo predhodno privolitev posameznika v skladu z veljavnimi
          predpisi s podro&#269;ja varstva osebnih podatkov. Po izpolnjenem in
          vrnjenem vpra&scaron;alniku uporabnika se ti podatki izbri&scaron;ejo,
          saj je bil namen uporabe dose&#382;en in niso ve&#269; potrebni za
          nadaljnjo obdelavo.
        </span>
      </p>
      <p class="c3">
        <span class="c1">
          6. Storitev zagotavljanja komunikacije uporabnikom
        </span>
      </p>
      <p class="c3">
        <span class="c4">
          Storitvi elektronskega obve&scaron;&#269;anja prek sporo&#269;il SMS
          in elektronske po&scaron;te se izvajata prek spletne aplikacije ali
          vmesnika, ki ga ponuja izvajal&#269;ev podizvajalec. Pred
          obve&scaron;&#269;anjem oziroma v sklopu Posveta z zdravnikom storitev
          za po&scaron;iljanja obvestil npr. obvestila o terminih bo NYD od
          uporabnika kot prejemnika pridobil jasno, nedvoumno in s
          Splo&scaron;no uredbo o varstvu podatkov ter vso relevantno zakonodajo
          skladno privolitev za prejem tovrstnih sporo&#269;il. &nbsp;Podlaga za
          sporo&#269;ilo je poleg Splo&scaron;ne uredbe o varstvu podatkov tudi
          Zakon o elektronskih komunikacijah (ZEKom-1). Sporo&#269;ila SMS in
          elektronska po&scaron;ta so posredovani v zunanji sistem,v skladu{" "}
        </span>
        <span class="c4">s politiko zasebnosti.</span>
        <sup>
          <a href="#cmnt2" id="cmnt_ref2">
            [b]
          </a>
        </sup>
        <span>&nbsp;</span>
      </p>
      <p class="c3">
        <span class="c1">7. Pravice intelektualne lastnine</span>
      </p>
      <p class="c3">
        <span class="c0">
          Spletna stran in njena vsebina sta intelektualna lastnina NYD oziroma
          njenih avtorjev ter se ne sme, razen na na&#269;in dolo&#269;en v
          Splo&scaron;nih pogojih uporabe, uporabljati brez izrecnega pisnega
          soglasja NYD. NYD, NYD.care, logo, vse slike, fotografije in besedila
          ter vsa vsebina spletne strani, grafike in ikone gumbov predstavljajo
          intelektualno lastnino NYD. Vse ostale znamke, imena produktov in
          imena dru&#382;b ali logotipi so last njihovih lastnikov. Razen v
          primerih, jasno dolo&#269;enih na Spletni strani, je vsebino Spletne
          strani prepovedano kopirati, tiskati, prena&scaron;ati, prikazovati,
          prodajati, objavljati, shranjevati, spreminjati &nbsp;in uporabljati v
          komercialne namene brez predhodnega pisnega soglasja NYD.
        </span>
      </p>
      <p class="c3">
        <span class="c0">
          Logotipi, imena dru&#382;b, blagovne znamke in druge znamke, ki so
          prikazane na Spletni strani, so lahko za&scaron;&#269;itene znamke in
          so, bodisi v lasti NYD ali Dru&scaron;tvo plju&#269;nih in alergijskih
          bolnikov Slovenije &ndash; DPABS, bodisi objavljene s soglasjem
          nosilca industrijskih pravic na teh znamkah. Njihova uporaba je brez
          predhodnega pisnega soglasja NYD ali tretjih oseb prepovedana.
        </span>
      </p>
      <p class="c3">
        <span class="c1">8. Izklju&#269;itev odgovornosti</span>
      </p>
      <p class="c3">
        <span class="c0">
          Razen, &#269;e je eksplicitno dolo&#269;eno v teh Splo&scaron;nih
          pogojih uporabe, NYD ne daje nobenih zavez in izklju&#269;uje vsa
          jamstva, eksplicitna ali implicitna, v najve&#269;jem obsegu
          mo&#382;nem v skladu z veljavno zakonodajo glede ustreznosti,
          zanesljivosti, razpolo&#382;ljivosti, pravo&#269;asnosti in
          to&#269;nosti informacij ter vsebine spletne strani in povezav do
          spletnih strani tretjih na na&scaron;i spletni strani. Spletna stran,
          informacije in vsebina na njej ter s spletno stranjo povezane grafike
          so posredovane brez kakr&scaron;nega koli jamstva s strani NYD.
        </span>
      </p>
      <p class="c3">
        <span class="c0">
          Podatki, ki so vsebovani na spletni strani so lahko neto&#269;ni ali
          imajo tipkarske napake. NYD ne odgovarja za &scaron;kodo zaradi
          mo&#382;nosti izgube uporabe spletne strani, podatkov, dobi&#269;ka,
          dobrega imena ali ugleda, telesnih po&scaron;kodb ali katerih koli
          drugih &scaron;kod, ki izvirajo iz (i) va&scaron;ega dostopanja do
          na&scaron;e spletne strani, (ii) va&scaron;e nezmo&#382;nosti uporabe
          spletne strani, (iii) kakr&scaron;nekoli informacije ali vsebine,
          vsebovane na na&scaron;i spletni strani, (iv) va&scaron;e informacije
          ali gradiva posredovanega na na&scaron;o spletno stran.
          Izklju&#269;itev odgovornosti velja ne glede na to, ali je
          &scaron;koda nastala na podlagi kr&scaron;itve pogodbe,
          od&scaron;kodninske odgovornosti, malomarnosti, objektivne
          odgovornosti ali na drug na&#269;in.
        </span>
      </p>
      <p class="c3">
        <span class="c1">9. Varstvo osebnih podatkov</span>
      </p>
      <p class="c3">
        <span class="c4">NYD zbrane osebne podatke obdeluje skladno s </span>
        <span class="c4 c9">politiko zasebnosti</span>
        <span class="c4">&nbsp;in </span>
        <span class="c4 c9">politiko uporabe pi&scaron;kotkov</span>
        <span class="c0">.</span>
      </p>
      <p class="c3">
        <span class="c1">10. Sprememba Splo&scaron;nih pogojev uporabe</span>
      </p>
      <p class="c3">
        <span class="c0">
          NYD si pridr&#382;uje pravico kadarkoli spremeniti vsebino teh
          Splo&scaron;nih pogojev uporabe. Spremenjeni Splo&scaron;ni pogoji
          uporabe urejajo dostop do in uporabo Spletne strani od trenutka objave
          na Spletni strani. Z dostopanjem in uporabo Spletne strani po
          uveljavitvi spremenjenih Splo&scaron;nih pogojev uporabe tako
          sogla&scaron;ate k uporabi tak&scaron;nih spremenjenih Splo&scaron;nih
          pogojev uporabe. Datum zadnje spremembe Splo&scaron;nih pogojev
          uporabe je objavljen spodaj.
        </span>
      </p>
      <p class="c3">
        <span class="c1">11. Kon&#269;ne dolo&#269;be</span>
      </p>
      <p class="c3">
        <span class="c0">
          Ti Splo&scaron;ni pogoji uporabe, skupaj s Politiko zasebnosti in
          Politiko uporabe pi&scaron;kotkov, objavljenima na Spletni strani,
          celovito urejajo razmerje med vami in NYD v zvezi z va&scaron;o
          uporabo Spletne strani.
        </span>
      </p>
      <p class="c3">
        <span class="c0">
          Za presojo teh Splo&scaron;nih pogojev uporabe se uporablja slovensko
          pravo. V primeru spora, ki izhaja iz in je v zvezi s temi
          Splo&scaron;nimi pogoji uporabe je pristojno stvarno pristojno
          sodi&scaron;&#269;e v Ljubljani. &nbsp;
        </span>
      </p>
      <p class="c3">
        <span class="c1">12. Kontakt</span>
      </p>
      <p class="c3">
        <span class="c0">
          V primeru kakr&scaron;nihkoli vpra&scaron;anj nas prosim kontaktirajte
          na info@nyd.care.
        </span>
      </p>
      <p class="c3">
        <span class="c4 c16">&copy; </span>
        <span class="c0">NYD d.o.o., Ljubljana september 2021.</span>
      </p>
      <p class="c2">
        <span class="c0"></span>
      </p>
      <div>
        <p class="c10">
          <span class="c6"></span>
        </p>
        <p class="c8">
          <span class="c6"></span>
        </p>
      </div>
    </div>
  );
}
