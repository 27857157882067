import React, { useLayoutEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";
import { Input } from "@material-ui/core";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import 'react-dropzone-uploader/dist/styles.css';

import axios from "axios";
import moment from "moment";

import deleteUpload from "../../assets/deleteUpload.svg";

import dateLeft from "../../assets/dateLeft.svg";
import dateLeftBlack from "../../assets/dateLeftBlack.svg"
import dateRight from "../../assets/dateRight.svg";
import sodelovanje from "../../assets/sodelovanje.svg";
import uploadImg from "../../assets/upload-icon.svg"
import Dropzone from 'react-dropzone'
import { Grid, TextareaAutosize } from "@material-ui/core";
import { set } from "date-fns/esm";



const getUploadParams = ({ meta, file }) => {
  return { url: '<FILE_UPLOAD_API_URL>' }
}

//Dinamic Images
const registration_success = require(`../../assets/${process.env.REACT_APP_PROJECT}/registration_success.svg`).default;






const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
    padding: "1em 5em",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  padded: {
    padding: "3em 15em 2em 15em",

  },
}));

export default function HorizontalLinearStepper() {

  import('./App.css');

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  const [phoneValue, setPhoneValue] = useState();

  // The first commit of Material-UI

  const [state, setState] = React.useState({
    dateCounter: 0,
    availableSlots: [],
    filteredCurrentDay: [],
    filteredSecondDay: [],
    filteredThirdDay: [],
    davcna: "",
    taxNumberValid: false,
    sprejemamPogoje: false,
    sprejemamPogojeDva: false,
    sprejemamPogojeTri: false,
    validState: "",
    validEmail: true,
    validPhone: true,
    sessionData: [],
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "070",
    birthday: "",
    firstQuestion: "",
    secondQuestion: "",
    thirdQuestion: "",
    fourthQuestion: "",
    fifthQuestion: "",
    painLevel: undefined,
    moski: false,
    zenski: false,
    selectedTimeSlot: "",
    selectedTimeSlotOld: "",
    selectedDoctor: "",
    policyNumber: "",
    emailInput: "",
    phoneInput: "",
    checkboxes: new Array(11).fill().map((_, i) => !i),
    file: []
  });

  function getSteps() {
    return ["Prijava", "Izbira termina", "Vprašalnik", "Zaključek"];
  }

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const onCheckBoxChange = (e, changedIndex) => {
    // it is a good habit to extract things from event variable
    const { checked } = e.target;

    if(changedIndex == painLevel) {
      setState({
        ...state,
        checkboxes: state.checkboxes.map((_, i) =>
          i === changedIndex ? (painLevel == undefined) ? false : false : false
        ),
        painLevel: undefined,
      });
    } else {
      setState({
        ...state,
        checkboxes: state.checkboxes.map((_, i) =>
          i === changedIndex ? e.target : false
        ),
        painLevel: changedIndex,
      });
    }
  };

  const formValidation = () => {
    var valid = false;

    if (firstName.length <= 1) {
      valid = false;
    } else {
      valid = true;

      if (lastName.length <= 1) {
        valid = false;
      } else {
        valid = true;

        if (email.length <= 1) {
          valid = false;
        } else {
          valid = true;

          if (phoneNumber.length <= 1) {
            valid = false;
          } else {
            valid = true;

            if (firstQuestion.length <= 1 || firstQuestion) {
              valid = false;
            } else {
              valid = true;
              if (secondQuestion.length <= 1) {
                valid = false;
              } else {
                valid = true;
                if (firstQuestion.length <= 1 || firstQuestion) {
                  valid = false;
                } else {
                  valid = true;
                }
              }
            }
          }
        }
      }
    }

    console.log(valid);
    return valid;
  };

  const stepValidator = (step) => {
    switch (step) {
      case 0:
        return (
          <Button
            variant="contained"
            color="primary"
            disabled={!taxNumberValid || !sprejemamPogoje || !sprejemamPogojeDva || !sprejemamPogojeTri}
            onClick={() => {
              handleNext();
              getAvailableSlotsForThreeDays();
            }}
            className={classes.button}
            id="greenButton"
          >
            {activeStep === steps.length - 1 ? "Potrdi" : "Naprej"}
          </Button>
        );
        break;
      case 1:
        return (
          <Button
            disabled={selectedTimeSlot.length < 1 ? true : false}
            variant="contained"
            color="primary"
            onClick={handleNext}
            className={classes.button}
            id="greenButton"
          >
            {activeStep === steps.length - 1 ? "Potrdi" : "Naprej"}
          </Button>
        );
        break;
      case 2:
        return (
          <Button
            disabled={validState ? false : true}
            variant="contained"
            color="primary"
            onClick={handleNext}
            className={classes.button}
            id="greenButton"
          >
            {activeStep === steps.length - 1 ? "Potrdi" : "Naprej"}
          </Button>
        );
        break;
      case 3:
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              createSession(state);
              handleNext();
            }}
            className={classes.button}
            id="greenButton"
          >
            Potrdi
          </Button>
        );
    }
  };

  const createSession = async (state) => {
    console.log(state.birthday);
    // var dateString = state.birthday;
    // var momentObj = moment(dateString, "DD-MM-YYYY");
    // // var birthday = momentObj.format("YYYY-MM-DD"); // 2016-07-15
    // var isoString = new Date(birthday).toISOString();

    // let birthdayConverted = birthday.getFullYear() + '.' + birthday.getDay() + '.' + birthday.getDay();

    await createAppointment();
    await deleteSelectedTimeSlot(selectedTimeSlot.id);
  };

  const createAppointment = async () => {
    axios.post(`${process.env.REACT_APP_API_URL}sessions/`, {
      pacientId: policyNumber,
      doctorId: selectedTimeSlot.doctor,
      sessionDate: selectedTimeSlot.startTime,
      sessionStartTime: selectedTimeSlot.startTime,
      firstName: firstName,
      lastName: lastName,
      email: email,
      birthday: birthday,
      gender: moski ? "male" : "female",
      questions: [
        {
          question: "Kakšne težave imate in kdaj so se pojavile?",
          answer: firstQuestion
        },
        {
          question: "Kaj jih sproži / poslabša in kaj jih izboljša?",
          answer: secondQuestion
        },
        {
          question: "Če je bolečina: Kakšen tip bolečine je (ostra, zbadajoča, utripajoča, topa)? (Neobvezno)",
          answer: thirdQuestion
        },
        {
          question: "Ste že kdaj imeli podobne težave? (Neobvezno)",
          answer: fourthQuestion
        },
        {
          question: "Ali redno jemljete zdravila? (Neobvezno)",
          answer: fifthQuestion
        },
      ],
      options: {
        "policyIssuer" : "Merkur",
        "callType": 'video'
          },
      painLevel: painLevel,
      phone: '+386' + phoneNumber
    }).then((result) => {
      console.log("OVO JE REQUEST",result)
      var formData = new FormData();
      formData.append('id', result.data.id);
      file.map((item) => {
        formData.append("file", item);
      })
      updateSessionWithFiles(result,formData);
    }).catch(reason => {console.log("request nije yupsei", reason)});
  };


  const updateSessionWithFiles =  async (result,formData) => {
    axios.patch(`${process.env.REACT_APP_API_URL}sessions/${result.data.id}`,
     formData,
     {
       headers: {"Content-type": "multipart/form-data"}
     })
     .then((response) => console.log("THIS IS THE UPDATE REQUEST", response))
  }


  const deleteSelectedTimeSlot = async (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}availabilitySlots/${id}`)
      .then((response) => console.log(response));
  };

  const checkValidityOfTaxNumber = (taxNumber) => {
    console.log(taxNumber);
    axios(`${process.env.REACT_APP_API_URL}policies/${taxNumber}`).then(
      (result) => {
        if (result.data.taxNumber == taxNumber) {
          console.log(result.data);
          setState({
            ...state,
            taxNumberValid: true,
            policyNumber: result.data.policyNumber,
          });
        } else {
          setState({ ...state, taxNumberValid: false });
        }
      }
    );
  };

  const checkEmail = (email) => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    console.log(email, pattern.test(email));
    if (!pattern.test(email)) {
      console.log("Invalid email");
      setState({ ...state, validState: false });
      return false;
    }
    return true;
  };

  const checkPhone = (phone) => {
    var pattern = new RegExp(/^[\+]?[(]?[0]{1}[)]?[-\s\.]?[0-9]{8}$/);
    console.log(phone, pattern.test(phone));
    if (!pattern.test(phone)) {
      console.log("Invalid phone");
      setState({ ...state, validState: false });
      return false;
    }
    return true;
  };

  const {
    validState,
    dateCounter,
    selectedTimeSlot,
    selectedTimeSlotOld,
    availableSlots,
    davcna,
    taxNumberValid,
    sprejemamPogoje,
    sprejemamPogojeDva,
    sprejemamPogojeTri,
    firstName,
    lastName,
    email,
    phoneNumber,
    birthday,
    firstQuestion,
    secondQuestion,
    thirdQuestion,
    fourthQuestion,
    fifthQuestion,
    painLevel,
    moski,
    zenski,
    checkboxes,
    validEmail,
    validPhone,
    phoneInput,
    selectedDoctor,
    policyNumber,
    emailInput,
    file
  } = state;

  const removeDuplicateObjectFromArray = (array, key) => {
    var check = new Set();
    return array.filter((obj) => !check.has(obj[key]) && check.add(obj[key]));
  };

  const getAvailableSlotsForThreeDays = async () => {
    axios(`${process.env.REACT_APP_API_URL}availabilitySlots/slots`).then(
      (response) => {
        var datePlusOne = new Date().setDate(new Date().getDate() + 1);
        var datePlusTwo = new Date().setDate(new Date().getDate() + 2);

        var firstDay = response.data.filter(
          (item) => new Date(item.startTime).getDate() === new Date().getDate()
        );
        var secondDay = response.data.filter(
          (item) =>
            new Date(item.startTime).getDate() ===
            new Date(datePlusOne).getDate()
        );
        var thirdDay = response.data.filter(
          (item) =>
            new Date(item.startTime).getDate() ===
            new Date(datePlusTwo).getDate()
        );
        var firstDayFiltered = removeDuplicateObjectFromArray(
          firstDay,
          "startTime"
          );
          var secondDayFiltered = removeDuplicateObjectFromArray(
            secondDay,
            "startTime"
            );
            var thirdDayFiltered = removeDuplicateObjectFromArray(
              thirdDay,
              "startTime"
              );


        firstDayFiltered.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
        secondDayFiltered.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
        thirdDayFiltered.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())

        setState({
          ...state,
          availableSlots: response.data,
          filteredCurrentDay: firstDayFiltered,
          filteredSecondDay: secondDayFiltered,
          filteredThirdDay: thirdDayFiltered,
        });
      }
    );
  };



  const reserveSelectedTimeSlot = (id) => {
    console.log("WE NEED THIS TO UPDATE: OLD", selectedTimeSlot.id, "New State", id)
    axios.patch(`${process.env.REACT_APP_API_URL}availabilitySlots/${selectedTimeSlot.id}`, {
      pending: "false"
    });
    axios.patch(`${process.env.REACT_APP_API_URL}availabilitySlots/${id}`, {
      pending: "true"
    });
  };


  const renderDateTimeSlots = (dateCounter) => {
    switch (dateCounter) {
      case 0:
        return (
          <>
            {state.filteredCurrentDay.map((slot) => (
              <>
              {slot.pending == true ? <></> :   <div
              key={slot.id}
              data-id={slot.doctor}
              data-slot={slot.id}
              data-pending={slot.pending}
                className={`item ${
                  selectedTimeSlot.startTime === slot.startTime
                    ? "selected"
                    : ""
                }`}
                onClick={() => {
                  reserveSelectedTimeSlot(slot.id)
                  setState({ ...state, selectedDoctor: slot.doctor });
                  setState({ ...state, selectedTimeSlot: slot });
                }}
              >
                {console.log(
                  new Date(slot.startTime),
                  new Date(slot.startTime).getHours()
                )}
              {new Date(slot.startTime).getHours().toString().length <= 1 ? "0" + new Date(slot.startTime).getHours().toString() : new Date(slot.startTime).getHours().toString() }:
                {new Date(slot.startTime).getMinutes().toString().length <= 1 ? new Date(slot.startTime).getMinutes().toString() + "0" : new Date(slot.startTime).getMinutes().toString()}
              </div>}
              </>
            ))}
          </>
        );
        break;
      case 1:
        return (
          <>
            {state.filteredSecondDay.map((slot) => (
              <>
              {slot.pending == true ? <></> :   <div
                key={slot.id}
                data-id={slot.doctor}
                data-slot={slot.id}
                className={`item ${
                  selectedTimeSlot.startTime === slot.startTime
                    ? "selected"
                    : ""
                }`}
                onClick={() => {
                  reserveSelectedTimeSlot(slot.id)
                  setState({ ...state, selectedDoctor: slot.doctor });
                  setState({ ...state, selectedTimeSlot: slot });
                }}
              >
                {console.log(
                  new Date(slot.startTime),
                  new Date(slot.startTime).getHours()
                )}
              {new Date(slot.startTime).getHours().toString().length <= 1 ? "0" + new Date(slot.startTime).getHours().toString() : new Date(slot.startTime).getHours().toString() }:
                {new Date(slot.startTime).getMinutes().toString().length <= 1 ? new Date(slot.startTime).getMinutes().toString() + "0" : new Date(slot.startTime).getMinutes().toString()}
                </div>}
              </>
            ))}
          </>
        );
        break;
      case 2:
        return (
          <>
            {state.filteredThirdDay.map((slot) => (
               <>
               {slot.pending == true ? <></> : <div
                key={slot.id}
                data-id={slot.doctor}
                data-slot={slot.id}
                className={`item ${
                  selectedTimeSlot.startTime === slot.startTime
                    ? "selected"
                    : ""
                }`}
                onClick={() => {
                  reserveSelectedTimeSlot(slot.id)
                  setState({ ...state, selectedDoctor: slot.doctor });
                  setState({ ...state, selectedTimeSlot: slot });
                }}
              >
                {console.log(new Date(slot.startTime))}
                {new Date(slot.startTime).getHours().toString().length <= 1 ? "0" + new Date(slot.startTime).getHours().toString() : new Date(slot.startTime).getHours().toString() }:
                {new Date(slot.startTime).getMinutes().toString().length <= 1 ? new Date(slot.startTime).getMinutes().toString() + "0" : new Date(slot.startTime).getMinutes().toString()}
                </div>}
              </>
            ))}
          </>
        );
        break;
      default:
        return (
          <>
            {state.filteredCurrentDay.map((slot) => (
              <div
              key={slot.id}
              data-id={slot.doctor}
              data-slot={slot.id}
                className={`item ${
                  selectedTimeSlot === slot.startTime ? "selected" : ""
                }`}
                onClick={() => {
                  reserveSelectedTimeSlot(slot.id)
                  setState({ ...state, selectedTimeSlot: slot });
                }}
              >
               {new Date(slot.startTime).getHours().toString().length <= 1 ? "0" + new Date(slot.startTime).getHours().toString() : new Date(slot.startTime).getHours().toString() }:
                {new Date(slot.startTime).getMinutes().toString().length <= 1 ? new Date(slot.startTime).getMinutes().toString() + "0" : new Date(slot.startTime).getMinutes().toString()}
              </div>
            ))}
          </>
        );
    }
  };


  const updateUploadedFiles = (item, i) => {
    const filteredFile = file.filter(function(item, index) {return index !== i})
    console.log(filteredFile, i)
    setState({...state, file: filteredFile})
  }

  function getStepContent(step, state) {
    require("moment/locale/sl.js");

    switch (step) {
      case 0:
        return (
          <div className="davcnaForm">
        {process.env.REACT_APP_DEBUGGING === "true" ?  <h1>{process.env.REACT_APP_PROJECT} - DEBUGGING</h1> : <></>}
            <h1>Prijavite se</h1>
            <p>
              Za prijavo na videoposvet z zdravnikom vas prosimo, da vnesete
              vašo davčno številko.
            </p>
            <form className="form">
              <label style={{ padding: "2% 0 4% 0" }}>
                Davčna številka
                <TextField
                  id="outlined-full-width"
                  style={{ margin: 8 }}
                  placeholder="Davčna številka"
                  fullWidth
                  margin="normal"
                  onChange={(event) => {
                    setState({ ...state, davcna: event.target.value });
                    checkValidityOfTaxNumber(event.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </label>
              <div
                className="painLevel"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignContent: "center",
                  alignItems: "center",
                  margin: "0em 1em 0em 1em",
                }}
              >
                <input
                  style={{ marginRight: "1em"}}
                  type="checkbox"
                  checked={sprejemamPogoje}
                  onChange={(event) => {
                    setState({
                      ...state,
                      sprejemamPogoje: sprejemamPogoje ? false : true,
                    });
                  }}
                />
                <p style={{fontSize: "12px", textAlign: "justify"}}>
                Soglašam, da sem seznanjen in se v celoti strinjam s <a href="https://merkur.nyd.care/politika-zasebnosti" target="_blank" style={{texDecoration: "underline", color: "#007bff"}}>Politiko zasebnosti</a> in <a  href="https://merkur.nyd.care/splosni-pogoji-uporabe" target="_blank" style={{texDecoration: "underline", color: "#007bff"}}>Splošnimi pogoji</a> ter soglašam, da bom na moj e-naslov/telefon prejel/-a odgovor. Do preklica dovoljujem obdelavo mojih osebnih podatkov za namene opisane v Politiki zasebnosti.
                </p>
              </div>
              <div
                className="painLevel"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignContent: "center",
                  alignItems: "center",
                  margin: "0em 1em 0em 1em",
                }}
              >
                <input
                  style={{ marginRight: "1em"}}
                  type="checkbox"
                  checked={sprejemamPogojeDva}
                  onChange={(event) => {
                    setState({
                      ...state,
                      sprejemamPogojeDva: sprejemamPogojeDva ? false : true,
                    });
                  }}
                />
              <p style={{fontSize: "12px", textAlign: "justify"}}>
              Dovoljujem, da NYD d.o.o. posreduje podatke o uporabi storitve Dr. Merkur (osebno ime in priimek, nadaljna obravnava, napotitev, rezervacija termina, čas trajanja posveta, ocena zadovoljstva) Merkur zavarovalnica d.d., ki jih lahko do mojega preklica uporablja za namen uporabe storitve Dr. Merkur brez podatkov o zdravstvenem stanju, ki vključuje obliko analize uporabe storitve, skladno s <a href="https://www.merkur-zav.si/politika-zasebnosti" target="_blank" style={{texDecoration: "underline", color: "#007bff"}}>Politiko zasebnosti</a>. Z izbiro te izjave dajem Merkur zavarovalnica d.d. do preklica privolitev za obdelavo mojih osebnih podatkov, ki jih obdeluje na podlagi sklenjenih zavarovalnih pogodb, vključno s podatki iz tega obrazca za ugotavljanje potreb in zahtev, moje uporabe spletnih storitev Merkur zavarovalnica d.d. in mojih drugih poslovnih interakcij z Merkur zavarovalnica d.d.
              </p>
              </div>
              <div
                className="painLevel"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignContent: "center",
                  alignItems: "center",
                  margin: "0em 1em 0em 1em",
                }}
              >
                <input
                  style={{ marginRight: "1em"}}
                  type="checkbox"
                  checked={sprejemamPogojeTri}
                  onChange={(event) => {
                    setState({
                      ...state,
                      sprejemamPogojeTri: sprejemamPogojeTri ? false : true,
                    });
                  }}
                />
                <p  style={{fontSize: "12px", textAlign: "justify"}}>Zavedam se, da storitev Dr. Merkur ni namenjena nujnim primerom. V primeru življenjske ogroženosti moram takoj poklicati telefonsko številko 112.</p>
              </div>
            </form>
          </div>
        );
      case 1:
        return (
          <div className="davcnaForm">
            <h1>Izbira termina</h1>
            <p>
              Prosimo vas, da izberete termin, ki vam ustreza, za videoposvet z
              zdravnikom.
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                  alignContent: "flex-end",
                  cursor: "pointer",
                }}
              >
                <img
                  style={{ width: "55px", height: "55px" }}
                  src={(dateCounter != 0) ? dateLeftBlack : dateLeft}
                  onClick={() =>
                    setState({
                      ...state,
                      dateCounter: dateCounter == 0 ? 0 : dateCounter - 1,
                    })
                  }
                ></img>
              </div>
              <div
                style={{
                  flex: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ textAlign: "center" }}>
                  {/* Danes:<br></br> */}
                  {moment(
                    new Date(
                      new Date().setDate(new Date().getDate() + dateCounter)
                    ).toDateString()
                  )
                    .locale("sl")
                    .format("dddd	LL")
                    .toLocaleUpperCase()}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  alignContent: "flex-end",
                  cursor: "pointer",
                }}
              >
                <img
                  style={{ width: "55px", height: "55px" }}
                  src={dateRight}
                  onClick={() =>
                    setState({
                      ...state,
                      dateCounter: dateCounter == 2 ? 2 : dateCounter + 1,
                    })
                  }
                />
              </div>
            </div>
            <div className="timeSlots">{renderDateTimeSlots(dateCounter)}</div>
          </div>
        );
      case 2:
        return (
          <div className="davcnaForm">
            <h1>Izpolnite vprašalnik</h1>
            <p>
              Prosimo vas, da izpolnete vprašalnik, ki se nanaša na vaše
              zdravstveno stanje. Vprašalnik bo pomagal zdravniku pri pripravi
              na videoposvet.
            </p>

            <form>
              <Grid container spacing={3}>
              <Grid item  xs={12} xm={6} lg={6}>
                  <label style={{ padding: "2% 0 4% 0" }}>
                  Ime {'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}
                    <TextField
                      id="outlined-size-normal"
                      style={{ margin: 8 }}
                      placeholder="Ime"
                      fullWidth
                      type="text"
                      margin="normal"
                      value={firstName}
                      onChange={(event) => {
                        setState({
                          ...state,
                          firstName: event.target.value,
                          validState: formValidation(),
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </label>
                </Grid>
                <Grid item xs={12} xm={6} lg={6}>
                  <label style={{ padding: "2% 0 4% 0" }}>
                    Priimek
                    <TextField
                      id="outlined-size-normal"
                      style={{ margin: 8 }}
                      placeholder="Priimek"
                      fullWidth
                      type="text"
                      margin="normal"
                      value={lastName}
                      onChange={(event) => {
                        setState({
                          ...state,
                          lastName: event.target.value,
                          validState: formValidation(),
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <label style={{ padding: "2% 0 4% 0" }}>
                    Elektronski naslov
                    <TextField
                      error={!validEmail}
                      id="outlined-full-width"
                      style={{ margin: 8 }}
                      placeholder="Elektronski naslov"
                      fullWidth
                      type="email"
                      margin="normal"
                      value={emailInput}
                      onChange={(event) => {
                        setState({
                          ...state,
                          email: checkEmail(event.target.value)
                            ? event.target.value
                            : "",
                          validState: formValidation(),
                          validEmail: checkEmail(event.target.value),
                          emailInput: event.target.value
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </label>
                  {validEmail ? (
                    <></>
                  ) : (
                    <p
                      style={{
                        fontSize: "12px",
                        color: "red",
                        margin: "0 0 0 20px",
                        padding: "0",
                      }}
                    >
                      Preverite veljavnost podatkov!
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} style={{ padding: "0 12px" }}>
                  <label style={{ padding: "2% 0 4% 0" }}>
                    Številka mobilnega telefona
                    <TextField
                      error={!validPhone}
                      id="outlined-full-width"
                      style={{ margin: 8 }}
                      placeholder="Številka mobilnega telefona (070555855)"
                      fullWidth
                      margin="normal"
                      type="tel"
                      value={phoneInput}
                      onChange={(event) => {
                        setState({
                          ...state,
                          phoneNumber: checkPhone(event.target.value)
                            ? event.target.value
                            : "",
                          validState: formValidation(),
                          validPhone: checkPhone(event.target.value),
                          phoneInput:  event.target.value
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      inputComponent={() => (
                        <InputMask mask="(0)999 999 99 99" maskChar=" " />
                      )}
                    ></TextField>
                  </label>
                  {validPhone ? (
                    <></>
                  ) : (
                    <p
                      style={{
                        fontSize: "12px",
                        color: "red",
                        margin: "0 0 0 20px",
                        padding: "0",
                      }}
                    >
                      Neveljavna številka mobilnega telefona. Primer: 070555555
                    </p>
                  )}
                  {/* <PhoneInput
                   country="SL"
                    placeholder="Enter phone number"
                    value={phoneValue}
                    onChange={setPhoneValue}/> */}
                </Grid>
                <Grid item xs={12}>
                  <label style={{ padding: "2% 0 4% 0" }}>
                    Rojstni datum
                    <TextField
                      id="outlined-full-width"
                      style={{ margin: 8 }}
                      placeholder="Rojstni datum"
                      fullWidth
                      margin="normal"
                      value={birthday}
                      onChange={(event) => {
                        setState({
                          ...state,
                          birthday: event.target.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <label style={{ padding: "2% 0 4% 0" }}>Spol</label>
                  <div
                    className="painLevelGreen"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "2%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={(event) => {
                        setState({
                          ...state,
                          moski: true,
                          zenski: false,
                        });
                      }}
                    >
                      <input
                        style={{ marginRight: "1em" }}
                        type="checkbox"
                        checked={moski}
                        defaultChecked={moski}
                        onChange={(event) => {
                          setState({
                            ...state,
                            moski: true,
                            zenski: false,
                          });
                        }}
                      />
                      <p>Moški</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignContent: "center",
                        alignItems: "center",
                        marginLeft: "2em",
                        cursor: "pointer",
                      }}
                      onClick={(event) => {
                        setState({
                          ...state,
                          moski: false,
                          zenski: true,
                        });
                      }}
                    >
                      <input
                        style={{ marginRight: "1em" }}
                        type="checkbox"
                        checked={zenski}
                        defaultChecked={zenski}
                        onChange={(event) => {
                          setState({
                            ...state,
                            moski: false,
                            zenski: true,
                          });
                        }}
                      />
                      <p>Ženski</p>
                    </div>
                    {/* <FormControlLabel
                      style={{ padding: "2% 0 4% 0" }}
                      control={
                        <Checkbox
                          class="roundedChecbox"
                          checked={moski}
                          onChange={(event) => {
                            setState({ ...state, moski: true, zenski: false });
                          }}
                          name="moski"
                        />
                      }
                      label="Moški"
                    /> */}
                    {/* <FormControlLabel
                      style={{ padding: "2% 0 4% 0" }}
                      control={
                        <Checkbox
                          class="roundedChecbox"
                          checked={zenski}
                          onChange={(event) => {
                            setState({ ...state, zenski: true, moski: false });
                          }}
                          name="zenski"
                        />
                      }
                      label="Ženski"
                    /> */}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <label style={{ padding: "2% 0 4% 0" }}>
                    Kakšne težave imate in kdaj so se pojavile?
                    <TextField
                      id="outlined-full-width"
                      style={{ margin: 8 }}
                      placeholder="Opišite kakšne težave so se vam pojavile in kdaj so se začeli prvi znaki..."
                      fullWidth
                      margin="normal"
                      type="text"
                      multiline
                      value={firstQuestion}
                      onChange={(event) => {
                        setState({
                          ...state,
                          firstQuestion: event.target.value,
                          validState: event.target.value.length <= 1 ?  false : (secondQuestion.length < 1) ? false : true,
                        });
                        console.log("Prvo pitanje", formValidation())
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <label style={{ padding: "2% 0 4% 0" }}>
                    Kaj jih sproži / poslabša in kaj jih izboljša?
                    <TextField
                      id="outlined-full-width"
                      style={{ margin: 8 }}
                      placeholder="Opišite kaj vam sproži / poslabša in kaj izboljša vaše težave..."
                      fullWidth
                      type="text"
                      margin="normal"
                      multiline
                      value={secondQuestion}
                      onChange={(event) => {
                        setState({
                          ...state,
                          secondQuestion: event.target.value,
                          validState: event.target.value.length <= 1 ?  false : (firstQuestion.length < 1) ? false : true,
                        });
                        console.log("Drugo pitanje", formValidation())

                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <label style={{ padding: "2% 0 4% 0" }}>
                    Če je bolečina: Kakšen tip bolečine je (ostra, zbadajoča,
                    utripajoča, topa)? (Neobvezno)
                    <TextField
                      id="outlined-full-width"
                      style={{ margin: 8 }}
                      placeholder="Opišite kakšen je tip bolečine..."
                      fullWidth
                      type="text"
                      margin="normal"
                      multiline
                      value={thirdQuestion}
                      onChange={(event) => {
                        setState({
                          ...state,
                          thirdQuestion: event.target.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <label style={{ padding: "2% 0 4% 0" }}>
                    Kakšna je jakost bolečine (1-10)? (Neobvezno)
                  </label>
                  <div className="painLevelGreen">
                    {checkboxes.map((item, i) => {
                      if(i == 0) {

                      } else {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={
                              (e) =>
                                onCheckBoxChange(
                                  e,
                                  i
                                ) /* notice passing an index. we will use it */
                            }
                          >
                            <input
                              style={{ marginRight: "15%" }}
                              key={i}
                              type="checkbox"
                              checked={item}
                              defaultChecked={item}
                              onChange={
                                (e) =>
                                  onCheckBoxChange(
                                    e,
                                    i
                                  ) /* notice passing an index. we will use it */
                              }
                            />
                            <p>{i }</p>
                          </div>
                        );
                      }
                    })}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <label style={{ padding: "2% 0 4% 0" }}>
                    Ste že kdaj imeli podobne težave? (Neobvezno)
                    <TextField
                      id="outlined-full-width"
                      style={{ margin: 8 }}
                      placeholder="Povejte ali ste že kdaj imeli podobne težave in kakšne...."
                      fullWidth
                      type="text"
                      margin="normal"
                      multiline
                      value={fourthQuestion}
                      onChange={(event) => {
                        setState({
                          ...state,
                          fourthQuestion: event.target.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <label style={{ padding: "2% 0 4% 0" }}>
                    Ali redno jemljete zdravila? (Neobvezno)
                    <TextField
                      id="outlined-full-width"
                      style={{ margin: 8 }}
                      placeholder="Povejte ali redno jemljete zdravila, kakšna in zakaj...."
                      fullWidth
                      type="text"
                      margin="normal"
                      multiline
                      value={fifthQuestion}
                      onChange={(event) => {
                        setState({
                          ...state,
                          fifthQuestion: event.target.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </label>
                </Grid>

                <Grid item xs={12}>
                  <label style={{ padding: "2% 0 4% 0" }}>
                  Pripni izvid (Neobvezno)




                  <Dropzone type="file" multiple  noClick={true} onDrop={(acceptedFiles) => setState({...state, file: acceptedFiles})}>
  {({getRootProps, getInputProps}) => (

    <section  className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"  style={{ border: "1px dashed #a5d782",borderRadius: "8px"}} >
      <div {...getRootProps({
        accept: 'image/jpeg, image/png',
        noClick: true
      })}>
        <input {...getInputProps()} />
        <img  src={ uploadImg } style={{  display: "block", margin: "auto", marginTop: "20px" }}  />
             <p style={{ textAlign: "center", color: "#757575", fontSize: "0.95em" }} >Tukaj spustite datoteko ali jo izberite na računalniku</p>
      </div>

    </section>
  )}


</Dropzone>

</label>

                </Grid>
                <Grid item xs={12}>
                <label style={{ padding: "2% 0 4% 0" }}>

                {file.length < 1  ? <></> : <div className="drfile" style={{ margin: 8, width: "100%"}}>
                {     file.map((item, i) => (
    <li class="fieluploaded"  key={item.path} >
      <span>{item.path}</span> <span style={{marginRight: "1%"}}
      onClick={() => {
        updateUploadedFiles(item, i)
      }}
      ><img src={deleteUpload}></img></span>
    </li>
  ))}
       </div>
       }
       </label>
       </Grid>
              </Grid>
            </form>
          </div>
        );
      case 3:
        return (
          <div className="davcnaForm">
            <h1>Preverite vaše podatke</h1>
            <p>
              Prosimo vas, da preverite vnešene podatke in potrdite prijavo na
              videoposvet z zdravnikom.
            </p>

            <div className="dataDivider">
              <hr></hr>
              <h2>Vaš termin</h2>
              <h3>
                Dne:
                <span className="userData">
                  {new Date(selectedTimeSlot.startTime).toDateString()}
                </span>
              </h3>
              <h3>
                Čas:{" "}
                <span className="userData">
                {new Date(selectedTimeSlot.startTime).getHours().toString().length <= 1 ? "0" + new Date(selectedTimeSlot.startTime).getHours().toString() : new Date(selectedTimeSlot.startTime).getHours().toString() }:
                {new Date(selectedTimeSlot.startTime).getMinutes().toString().length <= 1 ? new Date(selectedTimeSlot.startTime).getMinutes().toString() + "0" : new Date(selectedTimeSlot.startTime).getMinutes().toString()}
                </span>
              </h3>
            </div>

            <div className="dataDivider">
              <hr></hr>
              <h2>Vaši podatki</h2>
              <h3>
                Ime: <span className="userData">{firstName}</span>
              </h3>
              <h3>
                Priimek: <span className="userData">{lastName}</span>
              </h3>
              <h3>
                Elektronski naslov: <span className="userData">{email}</span>
              </h3>
              <h3>
                Telefonska številka:{" "}
                <span className="userData">{phoneNumber}</span>
              </h3>
              <h3>
                Rojstni datum: <span className="userData">{birthday}</span>
              </h3>
              <h3>
                Spol:{" "}
                <span className="userData">{moski ? "Moški" : "Ženski"}</span>
              </h3>
            </div>


            <div className="dataDivider">
              <hr></hr>
              <h2>Vaši odgovori</h2>
              <div className="userDataAnsware">
                <h3>Kakšne težave imate in kdaj so se pojavile?</h3>
                <TextField
                  id="outlined-full-width"
                  style={{ border:"none!important" }}
                  fullWidth
                  disabled
                  margin="normal"
                  value={firstQuestion}
                  multiline
               />
              </div>

              <div className="userDataAnsware">
                <h3>Kaj jih sproži / poslabša in kaj jih izboljša?</h3>
                <TextField
                  id="outlined-full-width"
                  style={{ border:"none!important" }}
                  fullWidth
                  disabled
                  margin="normal"
                  value={secondQuestion}
                  multiline
               />
              </div>

              <div className="userDataAnsware">
                <h3>
                  Če je bolečina: Kakšen tip bolečine je (ostra, zbadajoča,
                  utripajoča, topa)?
                </h3>
                <TextField
                  id="outlined-full-width"
                  style={{ border:"none!important" }}
                  fullWidth
                  disabled
                  margin="normal"
                  value={thirdQuestion}
                  multiline
               />
              </div>

              {(painLevel === undefined) ? <></> :
              <div className="userDataAnsware">
                <h3>Kakšna je jakost bolečine (1-10)?</h3>
                <TextField
                  id="outlined-full-width"
                  style={{ border:"none!important" }}
                  fullWidth
                  disabled
                  margin="normal"
                  value={painLevel}
                  multiline
              />
              </div>
               }

              <div className="userDataAnsware">
                <h3>Ste že kdaj imeli podobne težave?</h3>
                <TextField
                  id="outlined-full-width"
                  style={{ border:"none!important" }}
                  fullWidth
                  disabled
                  margin="normal"
                  value={fourthQuestion}
                  multiline
              />
              </div>

              <div className="userDataAnsware">
                <h3>Ali redno jemljete zdravila?</h3>
                <TextField
                  id="outlined-full-width"
                  style={{ border:"none!important" }}
                  fullWidth
                  disabled
                  margin="normal"
                  value={fifthQuestion}
                  multiline
              />
              </div>

              <div className="userDataAnsware">
                <h3 style={{ marginBottom: "16px" }} >Vaš izvid</h3>


{file.length < 1  ? <></> : <div className="drfile" >
                {     file.map(file => (
    <li class="fieluploaded" key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))}
       </div>
       }

              </div>


              {/* <div className="userDataAnsware">
                <h3>Vaš izvid</h3>
                <div className="userFile">
                  <img></img>
                  <h4>- Lorem ipsum dolor sit amet.</h4>
                </div>
              </div> */}
            </div>
          </div>
        );
      default:
        return "Unknown step";
    }
  }

  const isStepOptional = (step) => {};

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root} id="questionaryRoot">
      <div className="allFontsToBeBoston" >
      <Stepper
        id="stepBoxQuestionary"
        classes={{ root: classes.padded }}
        alternativeLabel
        activeStep={activeStep}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div className="questionaryView">
        {console.log(state)}

        {activeStep === steps.length ? (
          <div className="stepBoxFinal">
            <div className="davcnaFormCenter davcnaForm">
              <div className="paddedBox">
                <h1>Uspešno ste rezervirali posvet</h1>
                <p>
                  Potrdilo o uspešni rezervaciji termina in vabilo na posvet
                  boste prejeli na elektronski naslov in preko SMS sporočila.
                </p>
              </div>
              <img src={registration_success}></img>
            </div>
          </div>
        ) : (
          <div className="stepBox">
            <Typography className={classes.instructions}>
              {getStepContent(activeStep, state)}
            </Typography>
            <div className="stepBoxControll">
              {activeStep === 0 ? (
                <></>
              ) : (
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}
                  style={{ background: "#E0E0E0" }}
                >
                  Nazaj
                </Button>
              )}
              {isStepOptional(activeStep) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                  className={classes.button}
                >
                  Skip
                </Button>
              )}

              {stepValidator(activeStep)}
            </div>
          </div>
        )}

        <img style={{ marginBottom: "25px" }} src={sodelovanje}></img>
      </div>
    </div>
    </div>
  );
}
